/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Dialog, DialogActions, FormControl, InputLabel, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getHhgEstimates, getHhgItems, getHhgMovingDates, getHhgSegments, postHhgEstimate, postHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';

import PrimaryHhgInfo from './primaryHhgInfo.component';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import classNames from 'classnames';

import { GET_HHG_ESTIMATES_FAILURE, GET_HHG_ITEMS_FAILURE, GET_HHG_MOVING_DATE_FAILURE, GET_HHG_SEGMENTS_FAILURE, POST_HHG_ESTIMATE_FAILURE, POST_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import EstimatesActuals from './estimatesActuals.component';
import NeedsAnalysis from './needsAnalysis.component';
import Shipment from './shipment.component';
import Spinner from 'modules/common/spinner.component';
import Storage from './storage.component';
import queryString from 'query-string';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
  },
  headerFixed: {
    zIndex: 4,
  },
  tabFixed: {
    paddingTop: '100px',
    zIndex: 3,
  }, 
  previewTabFixed: {
    paddingTop: '10px',
    zIndex: 3,
  },
  estimateHeaderFixed: {
    paddingTop: '150px',
    zIndex: 2,
  },
  previewEstimateHeaderFixed: {
    paddingTop: '15px',
    zIndex: 3,
  },
});

const HouseholdGoods = (props) => {    
  const { details, location, shouldShowSideDrawer, isReadOnly } = props;

  const [segmentId, setSegmentId] = useState(details.hhgSegments && details.hhgSegments[0] ? details.hhgSegments[0].segmentId : null);
  const [currentTab, setCurrentTab] = useState(queryString.parse(location.search).tab ? queryString.parse(location.search).tab : 'PRIMARY');
  const [showAddSegment, setShowAddSegment] = useState(false);
  const [newInitDate, setNewInitDate] = useState(new Date().toISOString().split('T')[0]);
  const [newDescription, setNewDescription] = useState('');
  const [newType, setNewType] = useState(0);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isLoadingMovingDates, setIsLoadingMovingDates] = useState(false);
  const [isLoadingItemsToBeMoved, setIsLoadingItemsToBeMoved] = useState(false);

  const [estimateId, setEstimateId] = useState(details.hhgEstimates && details.hhgEstimates[0] ? details.hhgEstimates[0].estimateId : null);
  const [showAddEstimate, setShowAddEstimate] = useState(false);
  const [newEstimateDescription, setNewEstimateDescription] = useState('');
  const [isLoadingEstimates, setIsLoadingEstimates] = useState(false);

  const types = [{ id: 0, name: 'Domestic' }, { id: 1, name: 'International' }];

  const createSegment = async () => {
    const authId = details.summary.authorizationId;

    setIsLoadingModal(true);

    const resp = await props.postHhgSegment({
      authorizationId: authId,
      type: newType,
      name: newDescription,
      initiationDate: newInitDate,
    });

    if (resp.type === POST_HHG_SEGMENT_FAILURE) {
      props.showToast('Failed to update the hhg segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else { 
      const allSegmentsResp = await props.getHhgSegments(authId);
      if (allSegmentsResp.type === GET_HHG_SEGMENTS_FAILURE) {
        props.showToast('Failed to fetch the hhg segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Segment created successfully.', { userMustDismiss: false, severity: 'success' });
        setSegmentId(resp.response.segmentId);
        setShowAddSegment(false);
      }
      setIsLoadingModal(false);
    }
  };

  const createEstimate = async () => {
    setIsLoadingModal(true);

    const resp = await props.postHhgEstimate({
      authorizationId: details.summary.authorizationId,
      segmentId,
      description: newEstimateDescription,
    });

    if (resp.type === POST_HHG_ESTIMATE_FAILURE) {
      props.showToast('Failed to create the new hhg estimate, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else { 
      const allEstimatesResp = await props.getHhgEstimates(segmentId);
      if (allEstimatesResp.type === GET_HHG_ESTIMATES_FAILURE) {
        props.showToast('Failed to fetch the hhg estimates, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Estimate created successfully.', { userMustDismiss: false, severity: 'success' });
        setEstimateId(resp.response.estimateId);
        setShowAddEstimate(false);
      }
      setIsLoadingModal(false);
    }
  };

  const changeTab = (tab) => {
    setCurrentTab(tab);
    const values = queryString.parse(location.search);
    values.tab = tab;
    props.history.replace(`${location.pathname}?${queryString.stringify(values)}`);
  };

  const getSegmentData = async () => {
    setIsLoadingMovingDates(true);
    const movingDatesResp = await props.getHhgMovingDates(segmentId);
    setIsLoadingMovingDates(false);
    if (movingDatesResp.type === GET_HHG_MOVING_DATE_FAILURE) {
      props.showToast('Failed to fetch the hhg moving dates, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }

    setIsLoadingItemsToBeMoved(true);
    const itemsToBeMovedResp = await props.getHhgItems(segmentId);
    setIsLoadingItemsToBeMoved(false);
    if (itemsToBeMovedResp.type === GET_HHG_ITEMS_FAILURE) {
      props.showToast('Failed to fetch the hhg items to be moved, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }

    setIsLoadingEstimates(true);
    const estimatesResp = await props.getHhgEstimates(segmentId);
    setIsLoadingEstimates(false);
    if (estimatesResp.type === GET_HHG_ESTIMATES_FAILURE) {
      props.showToast('Failed to fetch the hhg estimates, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      setEstimateId(estimatesResp.response && estimatesResp.response[0] ? estimatesResp.response[0].estimateId : null);
    }
  };

  useEffect(() => {
    if (segmentId) {
      getSegmentData();
    }
  }, [segmentId]);

  return (
    <Box>
      { location && (location.pathname.includes('expenses') || isReadOnly) &&
      <>
      <Paper square className={classNames(props.classes.previewHeaderFixed, props.classes.container, { [props.classes.drawerOpen]: shouldShowSideDrawer })}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
          <Box>
            <div className={props.classes.mb2}>
              <Typography variant="subtitle2">
                Segment
              </Typography>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                labelId={'segmentSelect'}
                key={'segmentSelect'}
                label={''}
                name={'segmentSelect'}
                value={segmentId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setSegmentId(e.target.value)
                }
              >
                {details && details.hhgSegments.map((opt) => (
                  <MenuItem key={opt.segmentId} value={opt.segmentId} sx={{ margin: 'dense' }}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Move Type
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details.authServices.householdGoods}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  HHG Status
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {'Initiated'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Policy
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details.authClientPolicies?.clientPolicyCode}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>

          </Box>
        </Box>
      </Paper>
      <Paper square className={classNames(props.classes.previewTabFixed, props.classes.container, { [props.classes.drawerOpen]: shouldShowSideDrawer })}>
        <Tabs
          variant="fullWidth"
          value={currentTab}
          onChange={(event, tab) => changeTab(tab)}
          sx={{}}
        >
          <Tab value={'PRIMARY'} label="Primary Information" />
          <Tab value={'NEEDS_ANALYSIS'} label="Needs Analysis" disabled={!segmentId} />
          <Tab value={'ESTIMATES'} label="Estimates/Actuals" disabled={!segmentId} />
          <Tab value={'SHIPMENT'} label="Shipment of Goods" disabled={!segmentId} />
          <Tab value={'STORAGE'} label="Storage" disabled={!segmentId} />
        </Tabs>
      </Paper>

      {currentTab === 'ESTIMATES' && segmentId != null && (
        <Paper square className={classNames(props.classes.previewEstimateHeaderFixed, props.classes.container, { [props.classes.drawerOpen]: shouldShowSideDrawer })}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
              <Select
                labelId={'estimateId'}
                key={'estimateId'}
                label={''}
                name={'estimateId'}
                value={estimateId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setEstimateId(e.target.value)
                }
              >
                {details && details.hhgEstimates && details.hhgEstimates.map((opt) => (
                  <MenuItem key={opt.estimateId} value={opt.estimateId} sx={{ margin: 'dense' }}>
                    {opt.description}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Paper>
      )}
      {segmentId == null && (
        <Box sx={{ padding: '20px', paddingTop: '165px', fontSize: '20px' }}>
          <b>No segment selected. Create a new segment above to get started.</b>
        </Box>
      )}
      {segmentId != null && (
        <Box sx={{ padding: '20px', paddingTop: currentTab === 'ESTIMATES' ? '20px' : '15px' }}>
          {currentTab === 'PRIMARY' && <PrimaryHhgInfo details={details} segmentId={segmentId} location={location} isLoadingMovingDates={isLoadingMovingDates} id={props.id} isReadOnly={isReadOnly} />}
          {currentTab === 'NEEDS_ANALYSIS' && <NeedsAnalysis details={details} segmentId={segmentId} location={location} isLoadingItemsToBeMoved={isLoadingItemsToBeMoved} id={props.id} isReadOnly={isReadOnly} />}
          {currentTab === 'ESTIMATES' && <EstimatesActuals estimateId={estimateId} details={details} location={location} segmentId={segmentId} isLoadingEstimates={isLoadingEstimates} id={props.id} isReadOnly={isReadOnly} />}
          {currentTab === 'SHIPMENT' && <Shipment details={details} segmentId={segmentId} location={location} id={props.id} isReadOnly={isReadOnly} />}
          {currentTab === 'STORAGE' && <Storage details={details} segmentId={segmentId} location={location} id={props.id} isReadOnly={isReadOnly} />}
        </Box>
      )}
      </>
      }
      { location && (!location.pathname.includes('expenses') && !isReadOnly) &&
      <>
        <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container, { [props.classes.drawerOpen]: shouldShowSideDrawer })}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
          <Box>
            <div className={props.classes.mb2}>
              <Typography variant="subtitle2">
                Segment
              </Typography>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                labelId={'segmentSelect'}
                key={'segmentSelect'}
                label={''}
                name={'segmentSelect'}
                value={segmentId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setSegmentId(e.target.value)
                }
              >
                {details && details.hhgSegments.map((opt) => (
                  <MenuItem key={opt.segmentId} value={opt.segmentId} sx={{ margin: 'dense' }}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
              <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddSegment(true)}> New Segment </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Move Type
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details.authServices.householdGoods}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  HHG Status
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {'Initiated'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Policy
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details.authClientPolicies?.clientPolicyCode}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }}> Cancel Service </Button>
            <Button color="secondary"variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#f0b11d', '&:hover': { backgroundColor: '#ba8a00' } }}> Set On Hold </Button>
            <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}> Complete Service </Button>
          </Box>
        </Box>
      </Paper>
      <Paper square className={classNames('position-fixed', props.classes.tabFixed, props.classes.container, { [props.classes.drawerOpen]: shouldShowSideDrawer })}>
        <Tabs
          variant="fullWidth"
          value={currentTab}
          onChange={(event, tab) => changeTab(tab)}
          sx={{}}
        >
          <Tab value={'PRIMARY'} label="Primary Information" />
          <Tab value={'NEEDS_ANALYSIS'} label="Needs Analysis" disabled={!segmentId} />
          <Tab value={'ESTIMATES'} label="Estimates/Actuals" disabled={!segmentId} />
          <Tab value={'SHIPMENT'} label="Shipment of Goods" disabled={!segmentId} />
          <Tab value={'STORAGE'} label="Storage" disabled={!segmentId} />
        </Tabs>
      </Paper>

      {currentTab === 'ESTIMATES' && segmentId != null && (
        <Paper square className={classNames('position-fixed', props.classes.estimateHeaderFixed, props.classes.container, { [props.classes.drawerOpen]: shouldShowSideDrawer })}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
              <Select
                labelId={'estimateId'}
                key={'estimateId'}
                label={''}
                name={'estimateId'}
                value={estimateId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setEstimateId(e.target.value)
                }
              >
                {details && details.hhgEstimates && details.hhgEstimates.map((opt) => (
                  <MenuItem key={opt.estimateId} value={opt.estimateId} sx={{ margin: 'dense' }}>
                    {opt.description}
                  </MenuItem>
                ))}
              </Select>
              <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddEstimate(true)}> New Estimate </Button>
            </Box>
            <Box>
              <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}> Send Estimate Request </Button>
            </Box>
          </Box>
        </Paper>
      )}
      
      {segmentId == null && (
        <Box sx={{ padding: '20px', paddingTop: '165px', fontSize: '20px' }}>
          <b>No segment selected. Create a new segment above to get started.</b>
        </Box>
      )}
      {segmentId != null && (
        <Box sx={{ padding: '20px', paddingTop: currentTab === 'ESTIMATES' ? '245px' : '165px' }}>
          {currentTab === 'PRIMARY' && <PrimaryHhgInfo details={details} segmentId={segmentId} location={location} isLoadingMovingDates={isLoadingMovingDates} id={props.id} />}
          {currentTab === 'NEEDS_ANALYSIS' && <NeedsAnalysis details={details} segmentId={segmentId} location={location} isLoadingItemsToBeMoved={isLoadingItemsToBeMoved} id={props.id} />}
          {currentTab === 'ESTIMATES' && <EstimatesActuals estimateId={estimateId} details={details} location={location} segmentId={segmentId} isLoadingEstimates={isLoadingEstimates} id={props.id} />}
          {currentTab === 'SHIPMENT' && <Shipment details={details} segmentId={segmentId} location={location} id={props.id} />}
          {currentTab === 'STORAGE' && <Storage details={details} segmentId={segmentId} location={location} id={props.id} />}
        </Box>
      )}
      </>
      }

      <Dialog open={showAddSegment} fullWidth={false} maxWidth={'lg'}>
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
          <Box sx={{ marginRight: '20px' }}>
            <TextField
              key={'newDescription'}
              label={'Name'}
              name={'newDescription'}
              required={true}
              disabled={false}
              value={newDescription} // formatDateForInput(values[summary.accessorKey])
              sx={{ maxWidth: '360px' }}
              margin="dense"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setNewDescription(e.target.value)}
            />
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
          <Box sx={{ marginRight: '20px' }}>
            <TextField
              key={'newInitDate'}
              label={'Initiation Date'}
              name={'newInitDate'}
              required={true}
              disabled={false}
              value={newInitDate} // formatDateForInput(values[summary.accessorKey])
              sx={{ maxWidth: '360px' }}
              margin="dense"
              variant="standard"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setNewInitDate(e.target.value)}
            />
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
          <Box>
            <FormControl sx={{ marginTop: '6px' }}>
              <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'hhgType'}>HHG Type</InputLabel>
              <Select
                labelId={'hhgType'}
                key={'hhgType'}
                label={'hhgType'}
                name={'hhgType'}
                required={true}
                disabled={false}
                value={newType}
                margin="dense"
                variant="standard"
                sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) => setNewType(e.target.value)}
              >
                {types.map((opt) => (
                  <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
        </Box>
        <DialogActions sx={{ p: '1.25rem' }}>
          {isLoadingModal && <Spinner />}
          {!isLoadingModal && 
            <>
              <Button onClick={() => setShowAddSegment(false)}>Cancel</Button>
              <Button onClick={createSegment} color="primary" type="submit" variant="contained" disabled={!newInitDate || !(newDescription && newDescription.length > 0)}>
                Create Segment
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>

      <Dialog open={showAddEstimate} fullWidth={false} maxWidth={'lg'}>
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
          <Box>
            <TextField
              key={'newEstimateDescription'}
              label={'Name'}
              name={'newEstimateDescription'}
              required={true}
              disabled={false}
              value={newEstimateDescription} // formatDateForInput(values[summary.accessorKey])
              sx={{ maxWidth: '360px', minWidth: '275px' }}
              margin="dense"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setNewEstimateDescription(e.target.value)}
            />
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
        </Box>
        <DialogActions sx={{ p: '1.25rem' }}>
          {isLoadingModal && <Spinner />}
          {!isLoadingModal && 
            <>
              <Button onClick={() => setShowAddEstimate(false)}>Cancel</Button>
              <Button onClick={createEstimate} color="primary" type="submit" variant="contained" disabled={!(newEstimateDescription && newEstimateDescription.length > 0)}>
                Create Estimate
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </Box>
  );

};

HouseholdGoods.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    shouldShowSideDrawer: state.layout.shouldShowSideDrawer,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getHhgSegments,
    postHhgSegment,
    showToast,
    getHhgMovingDates,
    getHhgItems,
    getHhgEstimates,
    postHhgEstimate,
  }),
)(HouseholdGoods);