/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Select, TextField, Tooltip, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuthDateSummary, getAuthorizationSummary, setAuthId, setLoadingComplete } from '../store/actions/authorizations.actions';
import { getAuthTaxAssistance, getTaxPoliciesByClientId } from '../store/actions/taxAssistance.actions';

import { checkDirty } from '../utils';
import { formatDateForInput } from 'utilities/common';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import SummaryCard from './summaryCard.component';
import ToolTip from 'modules/common/toolTip.component';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const TaxAssistance = (props) => {    
  const { clientTaxPolicies, details, isReadOnly } = props;

  const [formState, setFormState] = useState({
    effectiveDate: details && details.taxAssistance ? details.taxAssistance.effectiveDate : '',
    taxPolicyCode: details && details.taxAssistance ? details.taxAssistance.taxPolicyCode : '',
    taxPolicyId: details && details.taxAssistance ? details.taxAssistance.taxPolicyId : 0,
    payPeriodProcessingGroup: details && details.taxAssistance ? details.taxAssistance.payPeriodProcessingGroup : '',
    altPolicyBeginningDate: details && details.taxAssistance ? details.taxAssistance.altPolicyBeginningDate : '',
    altYearEndPolicyCode: details && details.taxAssistance ? details.taxAssistance.altYearEndPolicyCode : '',
    altYearEndPolicyId: details && details.taxAssistance ? details.taxAssistance.altYearEndPolicyId : 0,
    altPayPeriodPolicyCode: details && details.taxAssistance ? details.taxAssistance.altPayPeriodPolicyCode : '',
    altPayPeriodPolicyId: details && details.taxAssistance ? details.taxAssistance.altPayPeriodPolicyId : 0,
    altPolicyEndingDate: details && details.taxAssistance ? details.taxAssistance.altPolicyEndingDate : '',
    shortTermAssignmentChecked: details && details.taxAssistance ? details.taxAssistance.shortTermAssignmentChecked : false,
    shortTermAssignmentChanged: details && details.taxAssistance ? details.taxAssistance.shortTermAssignmentChanged : '',
  });

  const [isDirty, setIsDirty] = useState(false);

  const [searchingColumns] = useState([
    { name: 'id', title: 'Id' },
    { name: 'policyCode', title: 'Policy Code' },
    { name: 'description', title: 'Description' },
  ]);

  const [searchingColumnExtensions] = useState([
    { columnName: 'id', width: 150 },
    { columnName: 'policyCode', width: 200 },
    { columnName: 'description', width: 300 },
  ]);

  const payPeriodGroups = ['Pay Group 1', 'Pay Group 2', 'Pay Group 3', 'Pay Group 4'];

  const savePage = () => {
    
    const values = {
      authorizationId: props.id,
      effectiveDate: formState.effectiveDate && formState.effectiveDate.length !== 0 ? formState.effectiveDate : null,
      taxPolicyCode: formState.taxPolicyCode,
      taxPolicyId: formState.taxPolicyId,
      payPeriodProcessingGroup: formState.payPeriodProcessingGroup,
      altPolicyBeginningDate: formState.altPolicyBeginningDate && formState.altPolicyBeginningDate.length !== 0 ? formState.altPolicyBeginningDate : null,
      altYearEndPolicyCode: formState.altYearEndPolicyCode,
      altYearEndPolicyId: formState.altYearEndPolicyId,
      altPayPeriodPolicyCode: formState.altPayPeriodPolicyCode,
      altPayPeriodPolicyId: formState.altPayPeriodPolicyId,
      altPolicyEndingDate: formState.altPolicyEndingDate && formState.altPolicyEndingDate.length !== 0 ? formState.altPolicyEndingDate : null,
      shortTermAssignmentChecked: formState.shortTermAssignmentChecked,
      shortTermAssignmentChanged: formState.shortTermAssignmentChanged && formState.shortTermAssignmentChanged.length !== 0 ? formState.shortTermAssignmentChanged : null,
    };

    props.updateTaxAssistance(values);
  };

  useEffect(() => {
    setIsDirty(checkDirty((details.taxAssistance && details.taxAssistance.length === 0) ? {
      effectiveDate: undefined, taxPolicyCode: undefined, taxPolicyId: undefined, payPeriodProcessingGroup: undefined,
      altPolicyBeginningDate: undefined, altYearEndPolicyCode: undefined, altYearEndPolicyId: undefined, altPayPeriodPolicyCode: undefined,
      altPayPeriodPolicyId: undefined, altPolicyEndingDate: undefined, shortTermAssignmentChecked: undefined, shortTermAssignmentChanged: undefined,
    } : details.taxAssistance, formState));
  }, [formState]);

  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <Card className={props.classes.card}>
            <Box>
              <CardContent className={props.classes.cardContainer}>
                <div className="row justify-space-between align-center">
                  <div className={props.classes.iconRow}>
                    <Tooltip title={'Money'} key={'Tax Assistance'} placement="right">
                      <FontAwesomeIcon icon={['fas', 'money-bills']} size="2x" />
                    </Tooltip>
                    <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
                      Tax Assistance
                    </Typography>
                  </div>
                </div>
              </CardContent>
              <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 4, xl: 4 }} sx={{ paddingBottom: '16px' }}>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Effective Date
                        </Typography>
                      </div>
                      <TextField
                        key={'effectiveDate'}
                        label={''}
                        name={'effectiveDate'}
                        required={false}
                        disabled={isReadOnly}
                        value={formatDateForInput(formState.effectiveDate)}
                        sx={{ maxWidth: '360px' }}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setFormState({ ...formState, effectiveDate: e.target.value })
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Tax Assistance Policy
                        </Typography>
                      </div>
                      <AdvancedSearch labelText={''} displayValueAccessorKey={'policyCode'} saveIdValueAccessorKey={'id'} searchingCriteria={searchingColumns} searchingCriteriaExtensions={searchingColumnExtensions} searchingRows={clientTaxPolicies} value={formState.taxPolicyCode} id={formState.taxPolicyId}
                        setIdValue={(id, val) => setFormState({ ...formState, taxPolicyCode: val, taxPolicyId: id })} isReadOnly={isReadOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Pay Period Processing Group
                        </Typography>
                      </div>
                      <Select
                        labelId={'payPeriodProcessing'}
                        key={'payPeriodProcessing'}
                        label={''}
                        name={'payPeriodProcessing'}
                        value={formState.payPeriodProcessingGroup}
                        sx={{ maxWidth: '360px', minWidth: '180px' }}
                        disabled={isReadOnly}
                        onChange={(e) =>
                          setFormState({ ...formState, payPeriodProcessingGroup: e.target.value })
                        }
                      >
                        {payPeriodGroups.map((opt) => (
                          <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                            {opt}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box>
                        <div className={props.classes.mb2}>
                          <Typography variant="subtitle2">
                            Move Miles
                          </Typography>

                        </div>
                        <div className={props.classes.mb2}>
                          <Typography variant="body2">
                            {Math.floor(Math.random() * 5000)}
                          </Typography>
                        </div>
                      </Box>
                      <ToolTip label={'Mileage is derived automatically by Old and New Address Zip Codes.'} />
                    </Box>
                  </Grid>
                </Grid>
                <hr />
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '16px' }}>
                  <FormControl>
                    <FormControlLabel
                      key={'shortTermAssignment'}
                      name={'shortTermAssignment'}
                      label={'Short Term Assignment'}
                      labelPlacement="end"
                      control={<Checkbox />}
                      checked={formState.shortTermAssignmentChecked}
                      disabled={isReadOnly}
                      sx={{ minWidth: '180px', maxWidth: '360px', marginLeft: '0px', marginRight: '8px' }}
                      onClick={(e) =>
                      { setFormState({ ...formState, shortTermAssignmentChecked: e.target.checked, shortTermAssignmentChanged: new Date() });}
                      }
                    />   
                  </FormControl>
                  <ToolTip label={'This date is automatically populated with today\'s date when the check box is checked or unchecked. Expense transactions that were processed prior to this date would have been associated with the value in its previous state. For global comp collection purposes, if the Short Term checkbox is not checked and there is a date in the Date Changed field, this means there was an intent change from Short Term to Long Term as of that date and any comp collected with a transaction date prior to this date will be treated as Short Term when collected and any comp collected with a transaction date on or after this date will be treated as Long Term when collected. Since this is an intent date field, the user is allowed to change the date to reflect when the intent changed. However, this only affects any processing that occurs on or after the change to the date.'} />
                  <Box sx={{ marginLeft: '24px' }}>
                    <div className={props.classes.mb2}>
                      <Typography variant="subtitle2">
                        Short Term Assignment Changed Date
                      </Typography>
                    </div>
                    <div className={props.classes.mb2}>
                      <Typography variant="body2">
                        {formState && formState.shortTermAssignmentChanged ? (typeof formState.shortTermAssignmentChanged == 'string' ? new Date(formState.shortTermAssignmentChanged.split('.')[0]).toLocaleDateString('en-US') : formState.shortTermAssignmentChanged.toLocaleDateString('en-US')) : ''}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Card>

          <Card className={props.classes.card} sx={{ marginTop: '16px' }}>
            <Box>
              <CardContent className={props.classes.cardContainer}>
                <div className="row justify-space-between align-center">
                  <div className={props.classes.iconRow}>
                    <Typography variant="h5" sx={{ paddingLeft: '0px' }}>
                      Secondary Tax Assistance Policy
                    </Typography>
                  </div>
                </div>
              </CardContent>
              <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 4, xl: 4 }}>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Beginning Date
                        </Typography>
                      </div>
                      <TextField
                        key={'beginningDate'}
                        label={''}
                        name={'beginningDate'}
                        required={false}
                        disabled={isReadOnly}
                        value={formatDateForInput(formState.altPolicyBeginningDate)}
                        sx={{ maxWidth: '360px' }}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setFormState({ ...formState, altPolicyBeginningDate: e.target.value })
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Alt YearEnd Policy
                        </Typography>
                      </div>
                      <AdvancedSearch labelText={''} displayValueAccessorKey={'policyCode'} saveIdValueAccessorKey={'id'} searchingCriteria={searchingColumns} searchingCriteriaExtensions={searchingColumnExtensions} searchingRows={clientTaxPolicies} value={formState.altYearEndPolicyCode} id={formState.altYearEndPolicyId}
                        setIdValue={(id, val) => setFormState({ ...formState, altYearEndPolicyCode: val, altYearEndPolicyId: id })} isReadOnly={isReadOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Alt Pay Period Policy
                        </Typography>
                      </div>
                      <AdvancedSearch labelText={''} displayValueAccessorKey={'policyCode'} saveIdValueAccessorKey={'id'} searchingCriteria={searchingColumns} searchingCriteriaExtensions={searchingColumnExtensions} searchingRows={clientTaxPolicies} value={formState.altPayPeriodPolicyCode} id={formState.altPayPeriodPolicyId}
                        setIdValue={(id, val) => setFormState({ ...formState, altPayPeriodPolicyCode: val, altPayPeriodPolicyId: id })} isReadOnly={isReadOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Ending Date
                        </Typography>
                      </div>
                      <TextField
                        key={'endingDate'}
                        label={''}
                        name={'endingDate'}
                        required={false}
                        disabled={isReadOnly}
                        value={formatDateForInput(formState.altPolicyEndingDate)}
                        sx={{ maxWidth: '360px' }}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setFormState({ ...formState, altPolicyEndingDate: e.target.value })
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <SummaryCard dateSummary={details.dateSummary} id={props.id} hideEdit={true} />
        </Grid>
      </Grid>
      {!isReadOnly && (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: '24px' }}>
          <Button onClick={() => props.history.push(`/authorizations/${props.id}`)}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={savePage} disabled={!isDirty}>
            Save
          </Button>
        </Box>
      )}
    </Box>
  );

};

TaxAssistance.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getAuthTaxAssistance, 
    getTaxPoliciesByClientId,
    setAuthId,
    setLoadingComplete,
  }),
)(TaxAssistance);