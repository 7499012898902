/* eslint-disable no-useless-computed-key */
import { MenuItem, TextField, withStyles } from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';

import { chain } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import memoize from 'memoize-one';

//import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getTestApiV2, setPageTitle, showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';
import auth from 'auth/auth';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getExpenseSummaries, getExpenseLines } from './expenses.actions';
import { getClients } from 'modules/clients/clients.actions';
import { GET_EXPENSE_LINES_FAILURE, GET_EXPENSE_SUMMARIES_FAILURE } from './types';
import { GET_CLIENTS_FAILURE } from 'modules/clients/types';
import ExpensesGrid from './expensesGrid.component';

const styles = (theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
});

class ExpenseSummariesView extends PureComponent {
    state = {
        clientId: -1,
        expenseAnalystId: -1,
        reassigningRecord: null,
        deletingRecord: null,
        isReassigningRecord: false,
        isDeletingRecord: false,
        webAccessRecord: null,
        showWebAccessModal: false,
        showAlterWebAccessModal: false,
      };


  async componentDidMount() {
    this.props.setPageTitle('Expenses');

    if (!this.props.expenseSummaries) {
      let { type } = await this.props.getExpenseSummaries();
      if (type === GET_EXPENSE_SUMMARIES_FAILURE) {
        this.props.showToast('Failed to retrieve expenses, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }
    
    if (!this.props.expenseLines) {
        let { type } = await this.props.getExpenseLines();
        if (type === GET_EXPENSE_LINES_FAILURE) {
            this.props.showToast('Failed to retrieve expense lines, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
    }

    if (!this.props.clients) {
      let { type } = await this.props.getClients();
      if (type === GET_CLIENTS_FAILURE) {
        this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }
  }

  filterSummaries = memoize((expenseSummaries, clientId, expenseAnalystId) => {
    return expenseSummaries.filter((summary) => {
      return (clientId === -1 || summary.client.id === clientId) &&
        (expenseAnalystId === -1 || summary.expenseAnalyst.id === expenseAnalystId);
    });
  });

  getClientList = memoize((clients) => {
    if (!clients) {
      return [];
    }
    return chain(clients)
      .values()
      .uniqWith(clients, (a, b) => a.description === b.description)
      .value()
      .sort((a, b) => String(a.description).localeCompare(b.description))
      .filter((instance) => instance.description);
  });

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSummarySelect = (expenseId) => {
    this.props.history.push(`/expenses/${expenseId}`);
  };

  render() {
    Log.trace('RENDER', 'ClientsView');
    const { clientId, expenseAnalystId } = this.state;
    const { classes, clients, history, isLoading } = this.props;
    const sortedClients = this.getClientList(clients);
    let sortedExpenseAnalysts = [{id: 0, fullName: 'Test Analyst 1'}, {id: 1, fullName: 'Test Analyst 2'}, {id: 2, fullName: 'Test Analyst 3'}, {id: 3, fullName: 'Test Analyst 4'}, {id: 4, fullName: 'Test Analyst 5'}];

    let expenseSummaries = this.props.expenseSummaries ?? [];
    let expenseLines = this.props.expenseLines;

    return (
      <Fragment>
        <div className={classes.headerContainer}>
          <TextField
            select
            label="Clients"
            className={classes.textField}
            value={clientId}
            onChange={this.handleChange('clientId')}
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            <MenuItem value={-1}>
              <em>All Clients</em>
            </MenuItem>
            {sortedClients.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
          {auth.userHasRole('admin') &&
            <TextField
              select
              label="Expense Analyst"
              className={classes.textField}
              value={expenseAnalystId}
              onChange={this.handleChange('expenseAnalystId')}
              SelectProps={{
                autoWidth: true,
              }}
              variant="outlined"
            >
              <MenuItem value={-1}>
                <em>All Expense Analysts</em>
              </MenuItem>
              {sortedExpenseAnalysts.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.fullName}
                </MenuItem>
              ))}
            </TextField>
          }
        </div>
        { expenseLines && 
        <>
          <div className="px-2">
            <ExpensesGrid
              expenses={expenseSummaries}
              expenseLines={expenseLines ?? []}
              onSelect={this.handleSummarySelect}
              isLoading={isLoading}
              history={history}
            />
          </div>
        </>
        }
      </Fragment>
    );
  }
}

ExpenseSummariesView.propTypes = {
  setPageTitle: PropTypes.func,
  classes: PropTypes.object,
  clients: PropTypes.object,
  unmappedClients: PropTypes.array,
  isLoadingAuths: PropTypes.bool,
  showToast: PropTypes.func,
  profile: PropTypes.object,
  managerOptions: PropTypes.array,
  internalUsers: PropTypes.array,
  history: ReactRouterPropTypes.history,
};

const mapStateToProps = (state) => {
  const {
    expenses: { expenseSummaries, expenseLines, isLoading },
    clients: { clients },
  } = state;
  return { expenseSummaries, expenseLines, clients, isLoading };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    getTestApiV2,
    getClients,
    getExpenseSummaries,
    getExpenseLines,
  }),
)(ExpenseSummariesView);