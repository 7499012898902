/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Button, Card, CardContent, Checkbox, Fab, FormControl, FormControlLabel, Grid, MenuItem, Select, TextField, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import { getExpenseLinesBySummaryId, postExpenseLine, postMultipleExpenseLines, updateExpenseLine, updateExpenseSummary } from 'modules/expenses/expenses.actions';
import { Paper } from '@material-ui/core';
import ReportLineItem from './reportLineItem.component';
import { POST_EXPENSE_LINE_FAILURE, POST_EXPENSE_LINE_MULTIPLE_FAILURE, UPDATE_EXPENSE_SUMMARY_FAILURE } from 'modules/expenses/types';
import { checkDirty } from 'modules/authorizations/utils';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

const ReportDetailsCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [expenseLinesLoading, setExpenseLinesLoading] = useState(false);

  const { expenseInfo, expenseLines, isReadOnly } = props;

  const [formState, setFormState] = useState({...expenseInfo});

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const paidToOptions = [
    { title: 'Employee', id: 1 },
    { title: 'PrePaid Employee', id: 2 },
    { title: 'Vendor', id: 3 },
  ];

  const reimbursementOptions = [
    { title: 'Check to Old Address', id: 1 },
    { title: 'Check to Temp Address', id: 2 },
    { title: 'Check to New Address', id: 3 },
    { title: 'Direct Deposit', id: 4 },
    { title: 'Check to Manual Address', id: 5 },
    { title: 'Prepaid Card', id: 6 },
  ];

  const payInOptions = [
    { title: 'USD', id: 1 },
    { title: 'CAD', id: 2 },
    { title: 'EUR', id: 3 },
    { title: 'GBP', id: 4 },
    { title: 'JPY', id: 5 },
    { title: 'CHF', id: 6 },
  ];

  const batchOptions = [
    { title: 'ACH/WIRE', id: 1 },
    { title: 'AP TRACKING', id: 2 },
    { title: 'BILL TO EE - CHECK', id: 3 },
    { title: 'BILL TO EE - INTL', id: 4 },
    { title: 'BILL TO EE - ACH/WIRE', id: 5 },
    { title: 'BILL TO EE - TRACKING', id: 7 },
    { title: 'BLACKOUT', id: 8 },
    { title: 'CHECK', id: 9 },
    { title: 'CLIENT INTEREST', id: 10 },
    { title: 'CODING CORRECTION', id: 11 },
    { title: 'DEPOSIT', id: 12 },
    { title: 'EM TRACKING', id: 13 },
    { title: 'FEE', id: 14 },
    { title: 'INTERNATIONAL', id: 15 },
    { title: 'JOURNAL ENTRY', id: 16 },
    { title: 'ON HOLD', id: 17 },
  ];

  const bankOptions = [
    { title: 'BMO Harris Bank - USD - ****9999 (Dft/ACH)', id: 1 },
    { title: 'Wells Fargo Bank - USD - ****9999 (Dft/ACH)', id: 2 },
    { title: 'PNC Bank - USD - ****9999 (Dft/ACH)', id: 3 },
    { title: 'US Bank - USD - ****9999 (Dft/ACH)', id: 4 },
    { title: 'Bank of America - USD - ****9999 (Dft/ACH)', id: 5 },
    { title: 'Chase Bank - USD - ****9999 (Dft/ACH)', id: 6 },
  ];

  const notPayableOptions = [
    { title: 'Backout/Re-enter', id: 1 },
    { title: 'Cost Avoidance', id: 2 },
    { title: 'Paid by Client Through Payroll', id: 3 },
    { title: 'Tracking', id: 4 },
    { title: 'N/A', id: 5 },
  ];

  const saveReport = async () => {
    setIsLoading(true);

    let newVals = { ...formState };

    const resp = await props.updateExpenseSummary(newVals);
    if (resp.type === UPDATE_EXPENSE_SUMMARY_FAILURE) {
        props.showToast('Failed to update the expense summary, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
    } else { 
        props.showToast(`Successfully updated expense summary ${formState.expenseId}.`, {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
        setIsLoading(false);
        return true;
    }
  };

  const approveAll = async () => {
    setExpenseLinesLoading(true);

    let tempExpenseLines = expenseLines.map((te) => {
        return {...te, approved: te.amount, denied: '0', approvalStatus: 'Approved'};
    })

    const resp = await props.postMultipleExpenseLines(tempExpenseLines);
    if (resp.type === POST_EXPENSE_LINE_MULTIPLE_FAILURE) {
        props.showToast('Failed to update the expense lines, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }

    await props.getExpenseLinesBySummaryId(formState.expenseId);

    setExpenseLinesLoading(false);
  }

  const denyAll = async () => {
    setExpenseLinesLoading(true);

    let tempExpenseLines = expenseLines.map((te) => {
        return {...te, approved: '0', denied: te.amount, approvalStatus: 'Denied'};
    })

    const resp = await props.postMultipleExpenseLines(tempExpenseLines);
    if (resp.type === POST_EXPENSE_LINE_MULTIPLE_FAILURE) {
        props.showToast('Failed to update the expense lines, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }

    await props.getExpenseLinesBySummaryId(formState.expenseId);

    setExpenseLinesLoading(false);
  }

  const addLine = async () => {
    setIsLoading(true);

    let newVals = { expenseId: formState.expenseId, authorizationId: formState.authorizationId, amount: '0', approved: '0', denied: '0' };

    const resp = await props.postExpenseLine(newVals);
    if (resp.type === POST_EXPENSE_LINE_FAILURE) {
        props.showToast('Failed to add the expense line, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
    } else { 
        props.showToast(`Successfully added expense line ${resp.response.expenseLineId}.`, {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
        setIsLoading(false);
        return true;
    }
  }

  const completeReport = async () => {
    setIsLoading(true);

    let newVals = { ...formState };
    newVals.isExpenseApproved = true;

    const resp = await props.updateExpenseSummary(newVals);
    if (resp.type === UPDATE_EXPENSE_SUMMARY_FAILURE) {
        props.showToast('Failed to complete the expense summary, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
    } else { 
        props.history.push(`/authorizations/${formState.authorizationId}/expenses/approved`);
        props.showToast(`Successfully completed expense summary ${formState.expenseId}.`, {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
        setIsLoading(false);
        return true;
    }


  }

  const calcTotalApproved = () => {
    let totalApproved = 0;
    if (expenseLines) expenseLines.map((e) => totalApproved = totalApproved + parseFloat(e.approved));
    return totalApproved.toFixed(2);
  }

  const calcTotalDenied = () => {
    let totalDenied = 0;
    if (expenseLines) expenseLines.map((e) => totalDenied = totalDenied + parseFloat(e.denied));
    return totalDenied.toFixed(2);
  }

  const calcTotalPending = () => {
    let totalPending = 0;
    if (expenseLines) expenseLines.map((e) => totalPending = totalPending + (parseFloat(e.amount) - parseFloat(e.approved) - parseFloat(e.denied)));
    return totalPending.toFixed(2);
  }

  useEffect(() => {
    if(expenseInfo)
    {
        setIsDirty(checkDirty(expenseInfo, formState));
    }
  }, [formState]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '10px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'dollar-sign']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Report Details
                </Typography>
              </div>
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '10px', paddingTop: '0px' }}>
            <Grid container spacing={1}>
                <Grid item md={3}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Report Description
                            </Typography>
                        </div>
                        <TextField
                            key={'reportDescription'}
                            label={''}
                            name={'reportDescription'}
                            required={false}
                            disabled={isReadOnly}
                            value={formState.description}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFormState({ ...formState, description: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={3}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Bank Account
                            </Typography>
                        </div>
                        <Select
                            labelId={'bankAccount'}
                            key={'bankAccount'}
                            label={''}
                            name={'bankAccount'}
                            value={formState.bankAccount}
                            sx={{ maxWidth: '360px', minWidth: '180px' }}
                            onChange={(e) =>
                            setFormState({ ...formState, bankAccount: e.target.value })
                            }
                            disabled={isReadOnly}
                        >
                            {bankOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Paid To
                            </Typography>
                        </div>
                        <Select
                            labelId={'paidTo'}
                            key={'paidTo'}
                            label={''}
                            name={'paidTo'}
                            value={formState.paidTo}
                            sx={{ maxWidth: '360px', minWidth: '180px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                            setFormState({ ...formState, paidTo: e.target.value })
                            }
                        >
                            {paidToOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Reimbursement Type
                            </Typography>
                        </div>
                        <Select
                            labelId={'reimbursementType'}
                            key={'reimbursementType'}
                            label={''}
                            name={'reimbursementType'}
                            value={formState.reimbursementType}
                            sx={{ maxWidth: '360px', minWidth: '180px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                            setFormState({ ...formState, reimbursementType: e.target.value })
                            }
                        >
                            {reimbursementOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Pay in Currency Code
                            </Typography>
                        </div>
                        <Select
                            labelId={'payInCurrency'}
                            key={'payInCurrency'}
                            label={''}
                            name={'payInCurrency'}
                            value={formState.payInCurrency}
                            sx={{ maxWidth: '360px', minWidth: '180px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                            setFormState({ ...formState, payInCurrency: e.target.value })
                            }
                        >
                            {payInOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{paddingTop: '8px'}}>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Total Approved
                            </Typography>
                        </div>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                ${calcTotalApproved()}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{paddingTop: '8px'}}>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Total Denied
                            </Typography>
                        </div>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                ${calcTotalDenied()}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{paddingTop: '8px'}}>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Total Pending
                            </Typography>
                        </div>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                ${calcTotalPending()}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box sx={{paddingTop: '8px'}}>
                        <FormControl>
                            <FormControlLabel
                                key={'rushPayment'}
                                name={'rushPayment'}
                                label={'Rush Payment'}
                                labelPlacement="end"
                                control={<Checkbox disabled={isReadOnly} />}
                                checked={formState.rushPayment}
                                sx={{ minWidth: '180px', maxWidth: '360px', marginLeft: '0px', marginRight: '8px' }}
                                disabled={isReadOnly}
                                onClick={(e) =>
                                    { setFormState({ ...formState, rushPayment: e.target.checked });}
                                }
                            />   
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item md={1}>

                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Batch Type
                            </Typography>
                        </div>
                        <Select
                            labelId={'batchType'}
                            key={'batchType'}
                            label={''}
                            name={'batchType'}
                            value={formState.batchType}
                            sx={{ maxWidth: '360px', minWidth: '180px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                            setFormState({ ...formState, batchType: e.target.value })
                            }
                        >
                            {batchOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Not Payable
                            </Typography>
                        </div>
                        <Select
                            labelId={'notPayable'}
                            key={'notPayable'}
                            label={''}
                            name={'notPayable'}
                            value={formState.notPayable}
                            sx={{ maxWidth: '360px', minWidth: '180px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                            setFormState({ ...formState, notPayable: e.target.value })
                            }
                        >
                            {notPayableOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Equivalent Currency Code
                            </Typography>
                        </div>
                        <Select
                            labelId={'equivalentCurrency'}
                            key={'equivalentCurrency'}
                            label={''}
                            name={'equivalentCurrency'}
                            value={formState.equivalentCurrency}
                            sx={{ maxWidth: '360px', minWidth: '180px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                            setFormState({ ...formState, equivalentCurrency: e.target.value })
                            }
                        >
                            {payInOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{marginTop: '16px'}}>
                <Paper>
                    <Box sx={{padding: '16px', paddingTop: '8px'}}>
                        <Box sx={{paddingBottom: '10px', borderBottom: '2px solid'}}>
                            <Grid container spacing={1} columns={{ xs: 20, sm: 20, md: 20, xl: 20 }}>
                                <Grid item md={2}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>Status</Typography></Grid>
                                <Grid item md={2}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>CAT</Typography></Grid>
                                <Grid item md={5}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>Description</Typography></Grid>
                                <Grid item md={2}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>Pay Option</Typography></Grid>
                                <Grid item md={2}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>Amount</Typography></Grid>
                                <Grid item md={2}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>Approved</Typography></Grid>
                                <Grid item md={2}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>Denied</Typography></Grid>
                                <Grid item md={2}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>Attachment</Typography></Grid>
                                <Grid item md={1}><Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem'}}>Edit</Typography></Grid>
                            </Grid>

                        </Box>

                        {expenseLinesLoading && <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}><Spinner/></Box>}

                        {!expenseLinesLoading && expenseLines && expenseLines.map((row) => (
                            <Box sx={{paddingBottom: '10px', paddingTop: '10px', borderBottom: '2px solid'}}>
                                {expandedId !== row.expenseLineId && (
                                    <Grid container spacing={1} columns={{ xs: 20, sm: 20, md: 20, xl: 20 }}>
                                        <Grid item md={2}><Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>{row.approvalStatus}</Typography></Grid>
                                        <Grid item md={2}><Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>{row.cat}</Typography></Grid>
                                        <Grid item md={5}><Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>{row.description}</Typography></Grid>
                                        <Grid item md={2}><Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>{row.payOption}</Typography></Grid>
                                        <Grid item md={2}><Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>${row.amount}</Typography></Grid>
                                        <Grid item md={2}><Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>${row.approved}</Typography></Grid>
                                        <Grid item md={2}><Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>${row.denied}</Typography></Grid>
                                        <Grid item md={2}><Box>{row.hasAttachment ? <Typography variant="body2" sx={{ color: '#41B7C0', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>View Attachment</Typography> : <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>No Attachment</Typography>}</Box></Grid>
                                        <Grid item md={1}>
                                            <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setExpandedId(row.expenseLineId)}>
                                                <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                )}
                                {expandedId === row.expenseLineId && <ReportLineItem isReadOnly={isReadOnly} lineItem={row} closeLineItem={() => setExpandedId(null)} expenseCodes={props.expenseCodes}/>}
                            </Box>
                        ))}
                    </Box>
                </Paper>
            </Box>

            <Box sx={{marginTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {!isReadOnly && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginRight: '12px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={approveAll}> Approve All </Button> }
                    {!isReadOnly &&<Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginRight: '12px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={denyAll}> Deny All </Button> }
                    {!isReadOnly &&<Button color="secondary" variant="contained" sx={{ maxHeight: '40px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={addLine}> Add Line</Button> }
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {isLoading && <Spinner/>}
                    {!isReadOnly && !isLoading && <>
                        <Button color="primary" variant="contained" sx={{ maxHeight: '40px', marginRight: '12px' }} onClick={saveReport} disabled={!isDirty}> Save Report </Button>
                        <Button color="secondary" variant="contained" sx={{ maxHeight: '40px' }} onClick={completeReport}> Complete Report </Button>
                    </>}
                </Box>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateExpenseSummary,
    postExpenseLine,
    updateExpenseLine,
    getExpenseLinesBySummaryId,
    postMultipleExpenseLines,
    showToast,
  }),
)(ReportDetailsCard);