/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Card, CardContent, Grid, MenuItem, Select, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import { getExpenseLinesBySummaryId, postExpenseLine, postMultipleExpenseLines, updateExpenseLine, updateExpenseSummary } from 'modules/expenses/expenses.actions';
import HouseholdGoods from 'modules/authorizations/details/hhg/householdGoods.component';
import SimilarExpensesTable from './similarExpensesTable.component';
import TaxAssistance from '../../taxAssistance.component';
import { GET_AUTH_TAX_ASSISTANCE_FAILURE, GET_TAX_POLICIES_BY_CLIENT_FAILURE } from 'modules/authorizations/store/types/taxAssistanceTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { getAuthTaxAssistance, getTaxPoliciesByClientId } from 'modules/authorizations/store/actions/taxAssistance.actions';
import { updateAuthDestinationServices, getAuthDestinationServices, postAuthDestinationServices } from 'modules/authorizations/store/actions/destinationService.actions';
import { UPDATE_AUTH_DESTINATION_SERVICES_FAILURE, POST_AUTH_DESTINATION_SERVICES_FAILURE } from 'modules/authorizations/store/types/destinationServiceTypes';
import TempLivingView from '../../TemporaryLiving/tempLivingView.container';
import HomesaleServiceComponent from '../../homesale/homesaleService.component';
import GlobalServiceComponent from '../../global/globalService.component';
import DestinationServices from '../../destinationServices/destinationServices.component';
import CandidateServices from '../../candidateServices/candidateServices.component';
import InternServices from '../../candidateServices/internServices.component';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

const createFilePreviewOptions = (authServices, moveType) => {
  let previewOptions = ['Similar Expenses'];

  if (authServices?.taxAssistance === 'Completed' || authServices?.taxAssistance === 'Initiated') {
    previewOptions.push('Tax Assistance');
  }
  if (authServices?.homesale === 'Completed' || authServices?.homesale === 'Initiated') {
    previewOptions.push('Homesale');
  }
  if (authServices?.global === 'Completed' || authServices?.global === 'Initiated') {
    previewOptions.push('Global');
  }
  if (authServices?.temporaryLiving === 'Completed' || authServices?.temporaryLiving === 'Initiated') {
    previewOptions.push('Temporary Living');
  }
  if (authServices?.householdGoods === 'Completed' || authServices?.householdGoods === 'Initiated') {
    previewOptions.push('HHG');
  }
  if (authServices?.homefindRenter === 'Completed' || authServices?.homefindRenter === 'Initiated') {
    previewOptions.push('Destination Services');
  }
  if (
    authServices?.homefindBuyer === 'Completed' ||
    authServices?.homefindBuyer === 'Initiated' ||
    moveType === 'Candidate' || moveType === 'Intern'
  ) {
    previewOptions.push('Candidate Services');
  }

  return previewOptions;
};


const FilePreviewCard = (props) => {
  const { details, clientInfo, authId, location, vendors, inspectors, titleReps, brokerAgents } = props;
  const authServices = details?.authServices;
  const moveType = details?.summary?.moveType ?? '';

  const [isLoading, setIsLoading] = useState(false);
  const [contentToPreview, setContentToPreview] = useState('Similar Expenses');
  const [householdGoodsLoading, setHouseholdGoodsLoading] = useState(false);
  const [clientTaxPolicies, setClientTaxPolicies] = useState(null);

  const previewOptions = createFilePreviewOptions(authServices, moveType);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });  

  const getTaxAssistanceData = async () => {
    setIsLoading(true); 

    if(!details.taxAssistance) {
      let taxAssistanceResp = await props.getAuthTaxAssistance(authId);
      if (taxAssistanceResp.type === GET_AUTH_TAX_ASSISTANCE_FAILURE) {
        this.props.showToast('Failed to retrieve tax policies, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if(!clientTaxPolicies){
      let { type, response } = await props.getTaxPoliciesByClientId(clientInfo.id);
      if (type === GET_TAX_POLICIES_BY_CLIENT_FAILURE) {
        this.props.showToast('Failed to retrieve client tax policies, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        setClientTaxPolicies(response);
      }
    }

    setIsLoading(false);
  };

  const updateDestinationService = async (values) => {
    setIsLoading(true);
    if (details.destinationServices && details.destinationServices.length !== 0) {
      const resp = await props.updateAuthDestinationServices(values);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update auth destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postAuthDestinationServices(values);
      if (resp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update auth destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  useEffect(() => {
    if (contentToPreview === 'Tax Assistance' && (!details.taxAssistance || !clientTaxPolicies)) {
      getTaxAssistanceData();
    }
  }, [contentToPreview]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '10px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between">
                <Box sx={{display: 'flex'}}>
                  <Tooltip title="Estimates" key="Estimates" placement="right">
                    <FontAwesomeIcon icon={['fas', 'file-contract']} size="2x" />
                  </Tooltip>
                  <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                    File Preview
                  </Typography>
                </Box>
                <Box sx={{display: 'flex'}}>
                    <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingTop: '16px' }}>
                            Content To Preview
                        </Typography>
                    </div>
                    <Select
                        labelId={'contentToPreview'}
                        key={'contentToPreview'}
                        label={''}
                        name={'contentToPreview'}
                        value={contentToPreview}
                        sx={{ maxWidth: '360px', minWidth: '180px' }}
                        onChange={(e) => {
                            setIsLoading(false);
                            setHouseholdGoodsLoading(false);                        
                            setContentToPreview(e.target.value)
                        }}
                    >
                        {previewOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                                {opt}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: '#41B7C0', fontWeight: 500, fontSize: '1rem', paddingTop: '20px'}}>View Attachment</Typography>
                </Box>
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '10px', paddingTop: '0px' }}>
            <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box>
                    {(contentToPreview === 'HHG') && !householdGoodsLoading &&
                      <HouseholdGoods details={details} clientInfo={clientInfo} id={authId} history={props.history} location={location} />
                    }
                    {(contentToPreview === 'Similar Expenses') && !isLoading && props.details &&
                      <SimilarExpensesTable details={details} authId={authId} history={props.history} location={location} summaryExpenseLines={details ? details.summaryExpenseLines : null} />
                    }
                    {(contentToPreview === 'Tax Assistance') && !isLoading && details && clientInfo && clientTaxPolicies &&
                      <TaxAssistance isReadOnly={true} details={details} clientInfo={clientInfo} clientTaxPolicies={clientTaxPolicies} id={authId} history={props.history} />
                    }
                    {(contentToPreview === 'Temporary Living') && <TempLivingView match={props.match} isReadOnly={true}/>}
                    {(contentToPreview === 'Homesale') && !isLoading && details && clientInfo &&
                      <HomesaleServiceComponent
                        isReadOnly={true}
                        details={details}
                        clientInfo={clientInfo}
                        id={authId} 
                        history={props.history} 
                        brokerAgents={brokerAgents}
                        vendors={vendors}
                        inspectors={inspectors}
                        titleReps={titleReps}
                        location={location} 
                        />
                    }
                    {(contentToPreview === 'Global') && !isLoading && details && clientInfo &&
                      <GlobalServiceComponent
                        isReadOnly={true}
                        details={details}
                        clientInfo={clientInfo}
                        id={authId}
                        history={props.history}
                        location={location}
                        />
                    }
                    {(contentToPreview === 'Destination Services') && <DestinationServices updateAuthDestinationServices={updateDestinationService} details={details} brokerAgents={brokerAgents} id={authId} history={props.history} location={location} />}
                    {(contentToPreview === 'Candidate Services') && 
                      <Grid>
                        <CandidateServices details={details} id={authId} brokerAgents={brokerAgents} history={props.history} location={location}/>
                        <InternServices details={details} id={authId} brokerAgents={brokerAgents} history={props.history} location={location} />
                      </Grid>
                    }

                    {(isLoading || !props.details) && <Spinner />}

                </Box>
                </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateExpenseSummary,
    postExpenseLine,
    updateExpenseLine,
    getExpenseLinesBySummaryId,
    postMultipleExpenseLines,
    showToast,
    getAuthTaxAssistance,
    getTaxPoliciesByClientId,
    updateAuthDestinationServices,
    postAuthDestinationServices,
    getAuthDestinationServices,
  }),
)(FilePreviewCard);