/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';
import { Box, Button, Fab, FormControl, Grid, MenuItem, Select, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme } from '@mui/material/styles';
import { formatDateForInput } from 'utilities/common';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import { postExpenseLine, updateExpenseLine } from 'modules/expenses/expenses.actions';
import { InputAdornment } from '@material-ui/core';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import { UPDATE_EXPENSE_LINE_FAILURE } from 'modules/expenses/types';
import { checkDirty } from 'modules/authorizations/utils';
import { SERVICE_FILTER_TYPES } from 'modules/authorizations/details/expenses/expenseConstants';
import AttachmentModal from 'modules/common/AttatchmentModal.component';
import AdvancedSearchComponent from 'modules/common/advancedSearch.component';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const ReportLineItem = props => {
  const { lineItem, expenseCodes, isReadOnly, setFormExpenseLineItems, voucherInfoForm, authorizationSummaries } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [formState, setFormState] = useState({ ...lineItem });
  const classes = useStyles();

  const [isOnHold, setIsOnHold] = useState(formState.onHoldDate !== null);

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const expenseCodeSearchInfo = {
    searchingColumns: [
      { name: 'expenseCodeCode', title: 'Expense Code' },
      { name: 'expenseCodeDescription', title: 'Description' },
      { name: 'classification', title: 'Classification' },
      { name: 'category', title: 'Category' }
    ],
    searchingColumnExtensions: [
      { columnName: 'expenseCodeCode', width: 150 },
      { columnName: 'expenseCodeDescription', width: 250 },
      { columnName: 'classification', width: 200 },
      { columnName: 'category', width: 200 }
    ],
    searchingRows: expenseCodes,
    idKey: 'expenseCodeId',
    nameKey: 'expenseCodeCode'
  };

  const saveItem = async () => {
    setIsLoading(true);

    let newVals = { ...formState };
    newVals.voucherId = lineItem.voucherId;
    newVals.authorizationId = lineItem.authorizationId;
    newVals.expenseLineId = lineItem.expenseLineId;
    newVals.isNewExpense = false;

    const resp = await props.updateExpenseLine(newVals);
    if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
      props.showToast('Failed to update the voucher line, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      props.showToast(`Successfully updated voucher line ${lineItem.expenseLineId}.`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      setFormExpenseLineItems(prevItems => prevItems.map(item => (item.expenseLineId === newVals.expenseLineId ? newVals : item)));
      setFormState(newVals);
      setIsLoading(false);
      return true;
    }
  };

  useEffect(() => {
    setIsDirty(checkDirty(lineItem, formState));
  }, [formState]);

  return (
    <Box>
      <Grid container spacing={1} columns={{ xs: 20, sm: 20, md: 20, xl: 20 }}>
        <Grid item md={3}>
          {voucherInfoForm.payToVendor ? (
            <AdvancedSearchComponent
              labelText={'Authorization'}
              displayValueAccessorKey={'transfereeName'}
              saveIdValueAccessorKey={'authorizationId'}
              searchingCriteria={[
                { name: 'authorizationId', title: 'Auth Id' },
                { name: 'transfereeName', title: 'Full Name' },
                { name: 'departure', title: 'Departure Location' },
                { name: 'destination', title: 'Destination Location' },
                { name: 'clientName', title: 'Client' }
              ]}
              searchingCriteriaExtensions={[
                { columnName: 'authorizationId', width: 150 },
                { columnName: 'transfereeName', width: 250 },
                { name: 'departure', width: 175 },
                { name: 'destination', width: 175 },
                { columnName: 'clientName', width: 175 }
              ]}
              searchingRows={authorizationSummaries.filter(a => a.clientName === voucherInfoForm.clientName)}
              id={formState.authorizationId}
              value={authorizationSummaries.find(s => s.authorizationId === formState.authorizationId)?.transfereeName}
              setIdValue={(id, value) => {
                setFormState({ ...formState, authorizationId: id });
              }}
              isReadOnly={isReadOnly}
            />
          ) : (
            <TextField
              key={'authorizationId'}
              label={''}
              name={'authorizationId'}
              required={false}
              disabled={true}
              value={formState.authorizationId}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </Grid>
        <Grid item md={1}>
          <Box
            sx={{
              paddingTop: '10px',
              display: 'flex',
              height: '100%'
            }}
          >
            <div className={classes.mb2}>
              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                {formState.cat ? formState.cat : <div>&mdash;</div>}
              </Typography>
            </div>
          </Box>
        </Grid>

        <Grid item md={3}>
          <Box>
            <FormControl key={''}>
              <AdvancedSearch
                key={'expenseCode'}
                labelText={''}
                displayValueAccessorKey={expenseCodeSearchInfo.nameKey}
                saveIdValueAccessorKey={expenseCodeSearchInfo.idKey}
                searchingCriteria={expenseCodeSearchInfo.searchingColumns}
                searchingCriteriaExtensions={expenseCodeSearchInfo.searchingColumnExtensions}
                searchingRows={expenseCodeSearchInfo.searchingRows}
                id={formState.expenseCodeId}
                value={formState.expenseCode}
                setIdValue={(id, value) => {
                  const expenseCodeInfo = expenseCodes.find(e => e.expenseCodeId === id);

                  setFormState({
                    ...formState,
                    expenseCode: value,
                    expenseCodeId: id,
                    expenseCodeDescription: expenseCodeInfo.expenseCodeDescription,
                    cat: expenseCodeInfo.category
                  });
                }}
                maxWidthsx={'360px'}
                isReadOnly={isReadOnly}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={2}>
          <TextField
            key={'amount'}
            label={''}
            name={'amount'}
            required={false}
            disabled={isReadOnly}
            value={formState.amount}
            sx={{ maxWidth: '500px' }}
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            onChange={e => setFormState({ ...formState, amount: e.target.value })}
          />
        </Grid>
        <Grid item md={5}>
          <TextField
            key={'description'}
            label={''}
            name={'description'}
            required={false}
            disabled={isReadOnly}
            value={formState.description}
            sx={{ maxWidth: '500px' }}
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            onChange={e => setFormState({ ...formState, description: e.target.value })}
          />
        </Grid>
        <Grid item md={2}>
          <FormControlLabel
            control={
              <Checkbox
                key={'onHold'}
                name={'On Hold'}
                checked={isOnHold}
                disabled={isReadOnly}
                onChange={e => {
                  if (!isOnHold) {
                    if (lineItem.onHoldDate) {
                      setFormState({ ...formState, onHoldDate: lineItem.onHoldDate });
                    } else {
                      setFormState({ ...formState, onHoldDate: new Date() });
                    }
                  } else {
                    setFormState({ ...formState, onHoldDate: null });
                  }
                  setIsOnHold(!isOnHold);
                }}
              />
            }
            sx={{ maxWidth: '500px' }}
          />
        </Grid>
        <Grid item md={2}>
          <AttachmentModal displayText={isReadOnly ? 'View Attatchments' : 'Add Attachment'} isReadOnly={isReadOnly} />
        </Grid>
        <Grid item md={1}>
          <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => props.closeLineItem()}>
            <FontAwesomeIcon size="1x" icon={['fas', 'xmark']} color={theme.palette.common.white} />
          </Fab>
        </Grid>

        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Debit Amount
              </Typography>
            </div>
            <TextField
              key={'debitAmount'}
              label={''}
              name={'debitAmount'}
              required={false}
              disabled={isReadOnly}
              value={formState.debitAmount}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              type={'number'}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              onChange={e => setFormState({ ...formState, debitAmount: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Credit Amount
              </Typography>
            </div>
            <TextField
              key={'creditAmount'}
              label={''}
              name={'creditAmount'}
              required={false}
              disabled={isReadOnly}
              value={formState.creditAmount}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              type={'number'}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              allowDecimal={true}
              onChange={e => setFormState({ ...formState, creditAmount: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Far Code
              </Typography>
            </div>
            <TextField
              key={'farCode'}
              label={''}
              name={'farCode'}
              required={false}
              disabled={isReadOnly}
              value={formState.farCode}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={e => setFormState({ ...formState, farCode: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Ex. Amount
              </Typography>
            </div>
            <TextField
              key={'exceptionAmount'}
              label={''}
              name={'exceptionAmount'}
              required={false}
              disabled={isReadOnly}
              value={formState.exceptionAmount}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              type={'number'}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              onChange={e => setFormState({ ...formState, exceptionAmount: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Ex. Reason
              </Typography>
            </div>
            <TextField
              key={'exceptionReason'}
              label={''}
              name={'exceptionReason'}
              required={false}
              disabled={isReadOnly}
              value={formState.exceptionReason}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={e => setFormState({ ...formState, exceptionReason: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Needs Approval Amount
              </Typography>
            </div>
            <TextField
              key={'needsApprovalAmount'}
              label={''}
              name={'needsApprovalAmount'}
              required={false}
              disabled={isReadOnly}
              value={formState.needsApprovalAmount}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              type={'number'}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              onChange={e => setFormState({ ...formState, needsApprovalAmount: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Needs Approval By
              </Typography>
            </div>
            <TextField
              key={'needsApprovalBy'}
              label={''}
              name={'needsApprovalBy'}
              required={false}
              disabled={isReadOnly}
              value={formState.needsApprovalBy}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={e => setFormState({ ...formState, needsApprovalBy: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Service
              </Typography>
            </div>
            <Select
              labelId={'service'}
              key={'service'}
              label={''}
              name={'service'}
              value={formState.service}
              sx={{ maxWidth: '140px', minWidth: '120px' }}
              disabled={isReadOnly}
              onChange={e => {
                setFormState({ ...formState, service: e.target.value });
              }}
            >
              {SERVICE_FILTER_TYPES.map(opt => (
                <MenuItem disabled={isReadOnly} key={opt} value={opt} sx={{ margin: 'dense' }}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Expense Key
              </Typography>
            </div>
            <TextField
              key={'expenseKey'}
              label={''}
              name={'expenseKey'}
              required={false}
              disabled={isReadOnly}
              value={formState.expenseKey}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={e => setFormState({ ...formState, expenseKey: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Ap Date
              </Typography>
            </div>
            <TextField
              key={'apDate'}
              label={''}
              name={'apDate'}
              required={false}
              disabled={isReadOnly}
              value={formatDateForInput(formState.apDate)}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              type="date"
              onChange={e => setFormState({ ...formState, apDate: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Payroll Date
              </Typography>
            </div>
            <TextField
              key={'payrollDate'}
              label={''}
              name={'payrollDate'}
              required={false}
              disabled={isReadOnly}
              value={formatDateForInput(formState.payrollDate)}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              type="date"
              onChange={e => setFormState({ ...formState, payrollDate: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Payroll Type
              </Typography>
            </div>
            <TextField
              key={'payrollType'}
              label={''}
              name={'payrollType'}
              required={false}
              disabled={isReadOnly}
              value={formState.payrollType}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={e => setFormState({ ...formState, payrollType: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Expense Date
              </Typography>
            </div>
            <TextField
              key={'expenseDate'}
              label={''}
              name={'expenseDate'}
              required={false}
              disabled={isReadOnly}
              value={formatDateForInput(formState.expenseDate)}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              type="date"
              onChange={e => setFormState({ ...formState, expenseDate: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                Route To
              </Typography>
            </div>
            <TextField
              key={'apRouteTo'}
              label={''}
              name={'apRouteTo'}
              required={false}
              disabled={isReadOnly}
              value={formState.apRouteTo}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={e => setFormState({ ...formState, apRouteTo: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box>
            <div className={classes.mb2}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                GL Number
              </Typography>
            </div>
            <TextField
              key={'glNumber'}
              label={''}
              name={'glNumber'}
              required={false}
              disabled={isReadOnly}
              value={formState.glNumber}
              sx={{ maxWidth: '500px' }}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={e => setFormState({ ...formState, glNumber: e.target.value })}
            />
          </Box>
        </Grid>
        <Grid item md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingTop: '24px',
              width: '100%'
            }}
          >
            {isLoading && <Spinner />}
            {!isReadOnly && !isLoading && (
              <Button color="primary" variant="contained" onClick={saveItem} disabled={!isDirty} sx={{ minWidth: '120px' }}>
                Save Line
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default compose(
  connect(null, {
    updateExpenseLine,
    postExpenseLine,
    showToast
  })
)(ReportLineItem);
