/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import AuthorizationDetails from './authorizationDetails.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../layout/types';
import { setPageTitle, showToast } from 'modules/layout/layout.actions';

import {
  getAuthClientPolicies, getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, 
  getDestinationAddress, getEmployeeInfo, getFamilyMembers, getFamilyPets, getSOInfo, getServices, 
  getTeam, getUserSettings, setAuthId, setLoadingComplete,
} from '../store/actions/authorizations.actions';

import { getAssignmentExtensions, getAssignmentTrips, getGlobalHomeServiceProperties, getGlobalInfo } from '../store/actions/global.actions';
import { getAuthDestinationServices, getHouseHuntingList, getOfferHistoryList } from '../store/actions/destinationService.actions';
import { getBrokerAgents, getVendorContacts, getVendors } from '../store/actions/dataManagement.actions';
import { getCandidateServices, getCandidateTrips, getCostEstimates, getInternServices } from '../store/actions/canidateTrip.actions';
import { getHhgSegments } from '../store/actions/houseHoldGoods.actions';
import { getHomesaleInfo, getHomesaleResaleOffers } from '../store/actions/homesale.actions';

import {
  GET_AUTH_CLIENT_POLICIES_FAILURE, GET_AUTH_DATE_SUMMARY_FAILURE, GET_AUTH_SERVICES_FAILURE, GET_AUTH_SUMMARY_FAILURE, 
  GET_AUTH_TEAM_FAILURE, GET_COMMUNICATION_INFO_FAILURE, GET_DEPARTURE_ADDRESS_FAILURE, GET_DESTINATION_ADDRESS_FAILURE, 
  GET_EMPLOYEE_INFO_FAILURE, GET_FAMILY_FAILURE, GET_FAMILY_PET_FAILURE, GET_USER_SETTINGS_FAILURE,
} from '../store/types/authorizationTypes';

import {
  GET_AUTH_DESTINATION_SERVICES_FAILURE, GET_AUTH_HOUSE_HUNTING_LIST_FAILURE, GET_AUTH_OFFER_HISTORY_LIST_FAILURE,
} from '../store/types/destinationServiceTypes';

import {
  GET_ALL_BROKER_AGENTS_FAILURE, GET_VENDORS_FAILURE, GET_VENDOR_CONTACT_FAILURE,
} from '../store/types/dataManagementTypes';

import {
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE, GET_ASSIGNMENT_EXTENSIONS_FAILURE, GET_ASSIGNMENT_TRIPS_FAILURE, 
  GET_GLOBAL_INFO_FAILURE,
} from '../store/types/globalTypes';

import {
  GET_ALL_HOMESALE_RESALE_OFFER_FAILURE, GET_HOMESALE_INFO_FAILURE,
} from '../store/types/homesaleTypes';

import {
  GET_ALL_CANDIDATE_TRIPS_FAILURE, GET_CANDIDATE_SERVICES_FAILURE, GET_COST_ESTIMATES_FAILURE, GET_INTERN_SERVICES_FAILURE,
} from '../store/types/canidateTripTypes';
import { GET_HHG_SEGMENTS_FAILURE } from '../store/types/houseHoldGoodsTypes';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect: {
    minWidth: 300,
  },
});

const AuthorizationDetailsView = (props) => {
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo =
    details && details.summary && props.unmappedClients
      ? props.unmappedClients.find((e) => e.description === details.summary.clientName)
      : null;

  useEffect(() => {
    props.setPageTitle('Authorization Details');

    async function fetchData() {
      const id = props.match.params.id;
      props.setAuthId(id);

      if (!details) {
        const [
          authSummaryResp,
          employeeInfoResp,
          authDateSummaryResp,
          communicationInfoResp,
          departureResp,
          destinationResp,
          authClientPoliciesResp,
          teamResp,
          authServiceResp,
          userSettingsResp,
          hhgSegmentsResp,
          vendorsResp,
          vendorContactsResp,
          destinationServicesResp,
          brokerAgentsResp,
          houseHuntingResp,
          offerHistoryResp,
          globalInfoResp,
          assignmentTripsResp,
          assignmentExtensionsResp,
          familyMembersResp,
          homesaleResp,
          petsResp,
          propertiesResp,
          resaleResp,
          candidateServicesResp,
          internServicesResp,
          candidateTripsResp,
          costEstimatesResp,
        ] = await Promise.all([
          props.getAuthorizationSummary(id),
          props.getEmployeeInfo(id),
          props.getAuthDateSummary(id),
          props.getCommunicationInfo(id),
          props.getDepartureAddress(id),
          props.getDestinationAddress(id),
          props.getAuthClientPolicies(id),
          props.getTeam(id),
          props.getServices(id),
          props.getUserSettings(id),
          props.getHhgSegments(id),
          props.getVendors(),
          props.getVendorContacts(),
          props.getAuthDestinationServices(id),
          props.getBrokerAgents(),
          props.getHouseHuntingList(id),
          props.getOfferHistoryList(id),
          props.getGlobalInfo(id),
          props.getAssignmentTrips(id),
          props.getAssignmentExtensions(id),
          props.getFamilyMembers(id),
          props.getHomesaleInfo(id),
          props.getFamilyPets(id),
          props.getGlobalHomeServiceProperties(id),
          props.getHomesaleResaleOffers(id),
          props.getCandidateServices(id),
          props.getInternServices(id),
          props.getCandidateTrips(id),
          props.getCostEstimates(id),
        ]);

        props.setLoadingComplete();

        if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
          props.showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
          props.showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authDateSummaryResp.type === GET_AUTH_DATE_SUMMARY_FAILURE) {
          props.showToast('Failed to retrieve auth summary info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (communicationInfoResp.type === GET_COMMUNICATION_INFO_FAILURE) {
          props.showToast('Failed to retrieve employee communication info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (departureResp.type === GET_DEPARTURE_ADDRESS_FAILURE) {
          props.showToast('Failed to retrieve departure address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (destinationResp.type === GET_DESTINATION_ADDRESS_FAILURE) {
          props.showToast('Failed to retrieve destination address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
          props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (teamResp.type === GET_AUTH_TEAM_FAILURE) {
          props.showToast('Failed to retrieve auth team, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authServiceResp.type === GET_AUTH_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve auth services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
          props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (userSettingsResp.type === GET_USER_SETTINGS_FAILURE) {
          props.showToast('Failed to retrieve employee settings info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (hhgSegmentsResp.type === GET_HHG_SEGMENTS_FAILURE) {
          props.showToast('Failed to retrieve hhg segments info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (vendorsResp.type === GET_VENDORS_FAILURE) {
          props.showToast('Failed to retrieve vendor info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (vendorContactsResp.type === GET_VENDOR_CONTACT_FAILURE) {
          props.showToast('Failed to retrieve vendor contact info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (destinationServicesResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve employee destination services info, please try again', {
            severity: TOAST_MESSAGE_SEVERITY_ERROR,
          });
          return;
        }

        if (brokerAgentsResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
          props.showToast('Failed to retrieve broker/agents, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (houseHuntingResp.type === GET_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
          props.showToast('Failed to retrieve employee house hunting trips, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (offerHistoryResp.type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
          props.showToast('Failed to retrieve employee offer history, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (globalInfoResp.type === GET_GLOBAL_INFO_FAILURE) {
          props.showToast('Failed to retrieve global service info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (assignmentTripsResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
          props.showToast('Failed to retrieve global trips info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (assignmentExtensionsResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
          props.showToast('Failed to retrieve global extensions info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (familyMembersResp.type === GET_FAMILY_FAILURE) {
          props.showToast('Failed to get family members please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
        if (homesaleResp.type === GET_HOMESALE_INFO_FAILURE) {
          props.showToast('Failed to retrieve homesale service info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
        if (petsResp.type === GET_FAMILY_PET_FAILURE) {
          props.showToast('Failed to retrieve pet info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
          
        if (propertiesResp.type === GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
          props.showToast('Failed to retrieve global home service properties info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (resaleResp.type === GET_ALL_HOMESALE_RESALE_OFFER_FAILURE) {
          props.showToast('Failed to retrieve resale offers info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

                    
        if (candidateServicesResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve candidate services info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (internServicesResp.type === GET_INTERN_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve intern services info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (candidateTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
          props.showToast('Failed to retrieve candidate trips info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (costEstimatesResp.type === GET_COST_ESTIMATES_FAILURE) {
          props.showToast('Failed to retrieve cost estimates info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
      }  
    }

    fetchData();
  }, []);

  return (
    <Box>
      {!props.isLoading && details && details.summary && (
        <AuthorizationDetails
          classes={props.classes}
          clientInfo={clientInfo}
          details={details}
          id={props.match.params.id}
          vendors={props.vendors}
        />
      )}
      {(props.isLoading || props.isFamilyLoading) && <FullscreenSpinner />}
    </Box>
  );
};

AuthorizationDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationDetails, isLoading, vendors } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading,
    vendors,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getSOInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    setAuthId,
    setLoadingComplete,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    setPageTitle,
    getTeam,
    getServices,
    getUserSettings,
    getHhgSegments,
    getVendors,
    getVendorContacts,
    getAuthDestinationServices,
    getBrokerAgents,
    getHouseHuntingList,
    getOfferHistoryList,
    getGlobalInfo,
    getAssignmentTrips,
    getAssignmentExtensions,
    getFamilyMembers,
    getFamilyPets,
    getHomesaleInfo,
    getGlobalHomeServiceProperties,
    getHomesaleResaleOffers,
    getCandidateServices,
    getInternServices,
    getCandidateTrips,
    getCostEstimates,
    showToast,
  }),
)(AuthorizationDetailsView);