import { Button, Typography, withStyles } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { showToast } from 'modules/layout/layout.actions';
import Dialog from 'modules/common/dialog.component';
import TRCInputValidator from './form/TRCInputValidator';
import { MuiChipsInput } from 'mui-chips-input';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 350,
  },
  okButton: {
    marginRight: 16,
    marginLeft: 16,
  },
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 40%',
  },
  answersInputRoot: {
    paddingBottom: 14,
  },
};

const initSelectedQuestion = () => {
  return {
    tempId: uuidv4(),
    text: '',
    answers: [],
  };
};

const formRef = React.createRef();

const BenefitQuestionDialog = (props) => {
  const { open, close: propsClose, classes, selectedQuestion: propsSelectedQuestion, setBenefitQuestion } = props;
  const [selectedQuestion, setSelectedQuestion] = useState(initSelectedQuestion());
  const [isEdit, setIsEdit] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const resetState = () => {
    setSelectedQuestion(initSelectedQuestion());
    setIsEdit(false);
  };

  const submit = () => {
    setBenefitQuestion(selectedQuestion);
    close();
  };

  const close = () => {
    resetState();
    propsClose();
  };

  const handleChange = (newChips) => {
    setSelectedQuestion({
      ...selectedQuestion,
      answers: newChips,
    })
  }

  useEffect(() => {
    if (propsSelectedQuestion) {
      setSelectedQuestion({ ...propsSelectedQuestion });
      setIsEdit(true);
    } else {
      setSelectedQuestion(initSelectedQuestion());
      setIsEdit(false);
    }
  }, [propsSelectedQuestion]);

  useEffect(() => {
    (async () => {
      if (!formRef.current) {
        return;
      }
      let formValid = await formRef.current.isFormValid();
      formValid = formValid && selectedQuestion.answers.length > 1;
      setIsFormValid(formValid);
    })();
  }, [selectedQuestion]);

  return (
    <Dialog
      open={open}
      title={`Benefit Question ${isEdit ? 'Edit' : 'Add'}`}
      content={
        <ValidatorForm ref={formRef} className={classes.root} onSubmit={() => {}}>
          <TRCInputValidator
            component={TextValidator}
            label="Question"
            onChange={(value) => setSelectedQuestion({ ...selectedQuestion, text: value })}
            name="question"
            value={selectedQuestion.text}
            required
            fullWidth
          />
          {/* <ChipInput
            value={selectedQuestion.answers}
            label="Answers"
            InputProps={{ inputProps: { maxLength: 20 }, readOnly: selectedQuestion.answers.length > 9 }}
            classes={{ inputRoot: classes.answersInputRoot }}
            chipRenderer={({ chip, handleDelete }) => (
              <Chip
                className="mr"
                key={chip}
                label={chip}
                onDelete={handleDelete}
              />
            )}
            newChipKeys={['Enter', 'Tab']}
            onAdd={(answer) => setSelectedQuestion({
              ...selectedQuestion,
              answers: [...selectedQuestion.answers, answer],
            })}
            onDelete={(answerToDelete) => setSelectedQuestion({
              ...selectedQuestion,
              answers: selectedQuestion.answers.filter((answer) => answer !== answerToDelete),
            })}
            variant="outlined"
            required
            fullWidth
          /> */}
          <MuiChipsInput value={selectedQuestion.answers} onChange={handleChange} />
          <Typography variant="caption">Press Enter after typing an answer</Typography>
        </ValidatorForm>
      }
      actions={
        <div className="row-no-grow justify-end my">
          <Button size="large" onClick={close}>Cancel</Button>
          <Button
            className={classes.okButton}
            size="large"
            variant="contained"
            color="primary"
            onClick={submit}
            disabled={!isFormValid}
          >
            Ok
          </Button>
        </div>
      }
    />
  );
};

BenefitQuestionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  setBenefitQuestion: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedQuestion: PropTypes.object,

  showToast: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  connect(null, { showToast }),
)(BenefitQuestionDialog);