import { Box, Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { getObjectId } from './util';
import BenefitQuestionCard from './benefitQuestionCard.component';
import BenefitQuestionDialog from './benefitQuestionDialog.component';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';

const useStyles = makeStyles((theme) => ({
  placeholderContent: {},
}));

export const BenefitQuestions = (props) => {
  const { version, setVersion, isReadOnly } = props;
  const benefitQuestions= version.questionDetails.filter((q) => !q.question || (q.question.benefitId === version.masterBenefitId));
  // const benefitQuestions= version.questionDetails;

  const [openBenefitQuestionDialog, setOpenBenefitQuestionDialog] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [confirmDeleteCallback, setConfirmDeleteCallback] = useState(null);

  const classes = useStyles(props);

  const editBenefitQuestion = (targetQuestion, newQuestion, vrsn) => {
    const targetQuestionId = getObjectId(targetQuestion);
    const questionIdx = vrsn.questionDetails.findIndex((question) => {
      const questionId = getObjectId(question);
      return questionId === targetQuestionId;
    });
    vrsn.questionDetails.splice(questionIdx, 1, newQuestion);
    setVersion({ ...vrsn });
  };

  return (
    <>
      <Box p={2}>
        <div className="row-no-grow justify-space-between p2">
          <Typography variant="h5">Benefit Questions</Typography>
          <Button
            color="primary"
            variant="outlined"
            style={{ visibility: isReadOnly ? 'hidden' : 'visible' }}
            onClick={() => setOpenBenefitQuestionDialog(true)}
          >
            Add
          </Button>
        </div>
        <Grid className="flex-grow" container spacing={0}>
          {benefitQuestions.length === 0 && (
            <Grid item xs={3} className="row p2">
              <Paper className={classNames('center-container', 'px-1', classes.placeholderContent)} elevation={4}>
                <Typography align="center" variant="h5" color="textSecondary">
                  No Questions Added
                </Typography>
              </Paper>
            </Grid>
          )}
          {benefitQuestions.length > 0 &&
          benefitQuestions.map((question) => (
            <Grid item key={getObjectId(question)} xs={3} className="row p2">
              <BenefitQuestionCard
                benefitQuestion={question}
                onEdit={() => {
                  setOpenBenefitQuestionDialog(true);
                  setSelectedQuestion(question);
                }}
                onDelete={() => setConfirmDeleteCallback(() => () => {
                  const targetQuestionId = getObjectId(question);
                  const questionIdx = version.questionDetails.findIndex((questionInList) => {
                    const questionId = getObjectId(questionInList);
                    return questionId === targetQuestionId;
                  });
                  version.questionDetails.splice(questionIdx, 1);
                  setVersion({ ...version, questionDetails: [...version.questionDetails] });
                  setConfirmDeleteCallback(null);
                })}
                isReadOnly={isReadOnly}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <BenefitQuestionDialog
        open={openBenefitQuestionDialog}
        close={() => {
          setOpenBenefitQuestionDialog(false);
          setSelectedQuestion(null);
        }}
        selectedQuestion={selectedQuestion}
        setBenefitQuestion={(newQuestion) => {
          if (selectedQuestion) {
            editBenefitQuestion(selectedQuestion, newQuestion, version);
          } else {
            setVersion({ ...version, questionDetails: [...benefitQuestions, newQuestion] });
          }
        }}
      />
      {confirmDeleteCallback &&
        <ConfirmationDialog
          onConfirm={confirmDeleteCallback}
          onCancel={() => {
            setConfirmDeleteCallback(null);
          }}
          open
          title="Delete Question?"
          confirmLabel="Delete"
        >
          <Typography>Are you sure you want to delete this question?</Typography>
        </ConfirmationDialog>
      }
    </>
  );
};

BenefitQuestions.propTypes = {
  version: PropTypes.object.isRequired,
  setVersion: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default BenefitQuestions;

