/* eslint-disable array-callback-return */
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as LayoutActions from 'modules/layout/layout.actions';
import * as SelfServiceActions from '../selfService.actions';
import { BenefitStatusEnum, TOGGLE_SENT_TO_VENDOR_FAILURE } from '../types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { displayPointsValue } from '../../../utilities/currencyFormatter';
import { formatAddress, formatDate } from 'utilities/common';
import Quantity from '../quantity.component';

const useStyles = makeStyles((theme) => ({
  unselectedBenefitSummary: {
    cursor: 'default',
    '&.MuiAccordionSummary-root:hover': {
      cursor: 'default',
    },
  },
}));

const SelfServiceBenefitList = (props) => {
  const {
    authPolicyDetails,
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const [compiledBenefitOptions, setCompiledBenefitOptions] = useState([]);
  const [unselectedBenefits, setUnselectedBenefits] = useState([]);
  const [expandedObjectId, setExpandedObjectId] = useState(-1);

  const theme = useTheme();

  useEffect(() => {
    const selectedOptions = authPolicyDetails.authSelections ? authPolicyDetails.authSelections.selectedOptions : [];
    const { benefits } = authPolicyDetails.policy;
    const getMatchingBenefit = (selectedOption) => benefits.find((ben) => ben.name === selectedOption.benefitName);
    if (!selectedOptions || !benefits) return;
    const options = selectedOptions
      .filter((selectedOption) => {
        const matchingBenefit = getMatchingBenefit(selectedOption);
        return matchingBenefit?.isComplete;
      })
      .map((selectedOption) => {
        const matchingBenefit = getMatchingBenefit(selectedOption);
        const matchingOption = matchingBenefit.options.find((opt) => opt.id === selectedOption.optionId);
        return {
          ...matchingOption,
          benefitStatus: matchingBenefit.status,
          quantityDescription: matchingBenefit.quantityDescription,
          vendorQuestionDetails: matchingBenefit.vendorQuestionDetails.map((vendorQuestionDetail) => {
            const questionAnswer = selectedOption.vendorQuestionAnswers.find((vendorQuestionAnswer) => vendorQuestionAnswer.questionDetailId === vendorQuestionDetail.id);
            return {
              ...vendorQuestionDetail, answer: questionAnswer ? questionAnswer.answer : null };
          }) };
      });

    const sortedOptions = options.sort((a, b) => {
      return new Date(b.confirmedDate ? b.confirmedDate : 0) - new Date(a.confirmedDate ? a.confirmedDate : 0);
    });
    setCompiledBenefitOptions(sortedOptions);
    setUnselectedBenefits(benefits.filter((ben) => !options.some((selectedOpt) => selectedOpt.benefitName === ben.name)));
  }, [authPolicyDetails]);

  const displayBenefitOptionSummary = (compiledOption) => {
    const title = `${compiledOption.benefitName} : ${compiledOption.name}`;
    const status = (compiledOption.isConfirmed ? 'CONFIRMED' : 'SELECTED') + (compiledOption.confirmedDate ? ` : ${formatDate(compiledOption.confirmedDate)}` : '');
    const points = displayPointsValue(compiledOption.cost);

    return (
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid xs={4} item container direction="column">
          <Grid item container direction="row">
            <Grid item xs={6}>
              <Typography>{status}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{points}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            {compiledOption.quantityDescription && <Quantity quantity={compiledOption.quantity} quantityDescription={compiledOption.quantityDescription} />}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {compiledOption.isConfirmed &&
            <FormControlLabel
              label="Sent to Vendor"
              control={
                <Checkbox
                  checked={compiledOption.sentToVendor}
                  onClick={(e) => e.stopPropagation()}
                  onChange={async (e) => {
                    const action = await dispatch(SelfServiceActions.toggleSentToVendor(authPolicyDetails.authorizationSummary.authorizationId, compiledOption.selectedOptionId, e.target.checked));
                    if (action.type === TOGGLE_SENT_TO_VENDOR_FAILURE) {
                      dispatch(LayoutActions.showToast('Failed to update sent to vendor flag.', { severity: TOAST_MESSAGE_SEVERITY_ERROR }));
                    }
                  }}
                />
              }
            />
          }
        </Grid>
        <Grid item xs={1}>
          {compiledOption.isConfirmed &&
            <a href={getMailToLink(compiledOption, authPolicyDetails.authorizationSummary)} onClick={(e) => e.stopPropagation()}>
              <FontAwesomeIcon color={theme.palette.secondary.main} icon={['far', 'paper-plane']} size="lg" />
            </a>
          }
        </Grid>
      </Grid>
    );
  };

  const displayUnselectedBenefitSummary = (benefit) => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <Typography variant="h5">{benefit.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{benefit.status === BenefitStatusEnum.NONE ? 'NOT STARTED' : benefit.status.replaceAll('_', ' ')}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getMailToLink = (option, authSummary) => {
    const name = `${authSummary.firstName} ${authSummary.lastName}`;
    let link = `mailto:?subject=TRC Referral ${name}&body=Lorem Ipsum...some intro from TRC%0d%0a%0d%0a`;
    link += getBodyTextLine('Transferee Name', name);
    link += getBodyTextLine('TRC Identifier', authSummary.authorizationId);
    link += getBodyTextLine('Departure', formatAddress({ city: authSummary.departureCity, state: authSummary.departureStateCode, country: authSummary.departureCountryCode }));
    link += getBodyTextLine('Destination', formatAddress({ city: authSummary.destinationCity, state: authSummary.destinationStateCode, country: authSummary.destinationCountryCode }));
    link += getBodyTextLine('Job Start Date', formatDate(authSummary.newJobStartDate));
    link += getBodyTextLine('Contact Mobile Phone', authSummary.contactMobilePhone);
    link += getBodyTextLine('Contact Home Phone', authSummary.contactHomePhone);
    link += getBodyTextLine('Contact Office Phone', authSummary.contactOfficePhone);
    link += getBodyTextLine('Contact Email', authSummary.contactEmail);
    link += getBodyTextLine('Benefit Chosen', `${option.benefitName} - ${option.name}` );
    link += getBodyTextLine(option.quantityDescription, option.quantity);
    link += '%0d%0aVendor Questions:%0d%0a';
    option.vendorQuestionDetails.forEach((vendorQuestion) => {
      link += getBodyTextLine(vendorQuestion.text, vendorQuestion.answer);
    });
    return link;
  };

  const getBodyTextLine = (fieldName, fieldValue) => {
    return fieldValue ? `${fieldName}: ${fieldValue}%0d%0a` : '';
  };

  const displayBenefitOptionDetails = (option) => {
    const { vendorQuestionDetails } = option;

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Vendor Questions</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box marginLeft={2}>
              <Grid container alignItems="center" direction="column">
                {vendorQuestionDetails.map((question) => {
                  if (question && question.text) {
                    return (
                      <Grid key={question.id} item container alignItems="center" direction="row" spacing={2}>
                        <Grid item><Typography variant="subtitle1">{question.text}</Typography></Grid>
                        <Grid item><Typography variant="subtitle2">{question.answer || '[Not Answered]'}</Typography></Grid>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const accordionChange = (id) => {
    setExpandedObjectId(id === expandedObjectId ? -1 : id);
  };

  let parsedCompliedBenefitOptions = [...compiledBenefitOptions];

  parsedCompliedBenefitOptions = parsedCompliedBenefitOptions.filter((obj1, i, arr) => 
    arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
  );

  return (
    <Box marginX={2} marginY={4}>
      {
        parsedCompliedBenefitOptions.map((compiledOption) => {
          if (compiledOption.benefitName) {
            return (
              <Accordion key={compiledOption.id}  expanded={expandedObjectId === compiledOption.id} onChange={()=> {accordionChange(compiledOption.id);}}>
                <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
                  {displayBenefitOptionSummary(compiledOption)}
                </AccordionSummary>
                <AccordionDetails>
                  {displayBenefitOptionDetails(compiledOption)}
                </AccordionDetails>
              </Accordion>
            )
          }
        })
      }
      {
        unselectedBenefits.map((ben) => (
          <Accordion key={ben.id} expanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ visibility: 'hidden' }} />} className={classes.unselectedBenefitSummary}>
              {displayUnselectedBenefitSummary(ben)}
            </AccordionSummary>
          </Accordion>
        ))
      }
    </Box>
  );
};

SelfServiceBenefitList.propTypes = {
  authPolicyDetails: PropTypes.object.isRequired,
};


export default SelfServiceBenefitList;
