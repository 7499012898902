/* eslint-disable no-case-declarations */
import {
  DELETE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS,
  GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  GET_EXPENSE_LINES_BY_AUTH_SUCCESS,
  GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS,
  POST_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  POST_EXPENSE_LINE_SUCCESS,
  UPDATE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  UPDATE_EXPENSE_LINE_SUCCESS,
  UPDATE_EXPENSE_SUMMARY_SUCCESS
} from 'modules/expenses/types';

const handleAuthExpensesReducer = (draft, action) => {
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};

  switch (action.type) {
    case GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].submittedExpenses = action.response;
      break;

    case GET_EXPENSE_LINES_BY_AUTH_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].expenseLines = action.response;
      break;

    case GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].summaryExpenseLines = action.response;
      break;

    case UPDATE_EXPENSE_LINE_SUCCESS: {
      const expenseLineItems = draft.authorizationDetails[draft.currentAuthId]?.expenseLines;
      if (expenseLineItems) {
        const indexToUpdate = expenseLineItems.findIndex(s => s.expenseLineId === action.response?.expenseLineId);
        if (indexToUpdate !== -1) {
          expenseLineItems[indexToUpdate] = action.response;
          draft.authorizationDetails[draft.currentAuthId].expenseLines = [...expenseLineItems];
        }

        if (draft.authorizationDetails[draft.currentAuthId]?.submittedExpenses.find(es => es.expenseId === action.response.expenseId)) {
          const summaryLineItems = draft.authorizationDetails[draft.currentAuthId]?.summaryExpenseLines;
          const matchingSubmittedExpense = draft.authorizationDetails[draft.currentAuthId]?.submittedExpenses.find(es => es.expenseId === action.response.expenseId);
          if (summaryLineItems && matchingSubmittedExpense) {
            const indexToUpdate = summaryLineItems.findIndex(s => s.expenseLineId === action.response?.expenseLineId);
            summaryLineItems[indexToUpdate] = action.response;
            draft.authorizationDetails[draft.currentAuthId].summaryExpenseLines = [...summaryLineItems];
          }
        }
      }
      break;
    }

    case UPDATE_EXPENSE_SUMMARY_SUCCESS:
      let tempSummaries = draft.authorizationDetails[draft.currentAuthId].submittedExpenses;
      const tempSummaryIndex = tempSummaries.findIndex(s => s.expenseId === action.response.expenseId);
      tempSummaries[tempSummaryIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].submittedExpenses = tempSummaries;
      break;

    case POST_EXPENSE_LINE_SUCCESS:
      if(draft.authorizationDetails[draft.currentAuthId]?.summaryExpenseLines){
        draft.authorizationDetails[draft.currentAuthId].summaryExpenseLines.push(action.response);
      }

      if(draft.authorizationDetails[draft.currentAuthId]?.expenseLines){
        draft.authorizationDetails[draft.currentAuthId].expenseLines.push(action.response);
      }

      break;

    case GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].customTaxes = action.response;

      break;

    case UPDATE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS: {
      const customTaxes = draft.authorizationDetails[draft.currentAuthId]?.customTaxes;
      if (customTaxes) {
        const indexToUpdate = customTaxes.findIndex(s => s.customTaxId === action.response?.customTaxId);
        if (indexToUpdate !== -1) {
          customTaxes[indexToUpdate] = action.response;
          draft.authorizationDetails[draft.currentAuthId].customTaxes = [...customTaxes];
        }
      }
      break;
    }

    case POST_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS: {
      const customTaxes = draft.authorizationDetails[draft.currentAuthId]?.customTaxes ?? [];
      draft.authorizationDetails[draft.currentAuthId].customTaxes = [...customTaxes, action.response];
      break;
    }

    case DELETE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS: {
      const customTaxes = draft.authorizationDetails[draft.currentAuthId]?.customTaxes;
      if (customTaxes) {
        const indexToUpdate = customTaxes.findIndex(customTax => customTax.customTaxId === action.response?.customTaxId);
        if (indexToUpdate !== -1) {
          customTaxes.splice(indexToUpdate, 1);
          draft.authorizationDetails[draft.currentAuthId].customTaxes = [...customTaxes];
        }
      }
      break;
    }

    default:
      break;
  }
  return draft;
};

export default handleAuthExpensesReducer;
