import { Box, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames';

import { displayPointsValue } from 'utilities/currencyFormatter';
import ProgressBar from 'modules/common/progressBar.component';

const useStyles = makeStyles((theme) => ({
  progressBar: {
    width: '100%',
    pointerEvents: 'none',
  },
  progressBarFill: {
    borderRadius: 0,
    pointerEvents: 'auto',
  },
  progressBarConfirmed: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
  },
  confirmedFill: {
    zIndex: theme.zIndex.mobileStepper,
  },
  tooltip: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    fontSize: '0.75rem',
  },
}));

const getPercentSpent = (policy) => {
  return policy.totalAmountSpent === 0 ? 0 : (policy.totalAmountSpent / policy.originalBudget) * 100;
};

const getPercentConfirmed = (policy) => {
  return policy.confirmedAmountSpent === 0 ? 0 : (policy.confirmedAmountSpent / policy.originalBudget) * 100;
};

const SelectionsProgressBar = (props) => {
  const { policy } = props;
  const theme = useTheme();
  const classes = useStyles();

  const percentSpent = getPercentSpent(policy);
  const percentConfirmed = getPercentConfirmed(policy);
  const pointsRemaining = policy.originalBudget - policy.totalAmountSpent;
  const pointsPending = policy.totalAmountSpent - policy.confirmedAmountSpent;
  const progressBarText = pointsRemaining < 0 ? `${displayPointsValue(Math.abs(pointsRemaining))} over your budget` : `${displayPointsValue(pointsRemaining)} remaining`;

  return (
    <Fragment>
      <Box position="relative" width="100%">
        <Tooltip placement="top" classes={{ tooltip: classes.tooltip }} title={`${displayPointsValue(pointsPending)} selected`}>
          <ProgressBar
            variant="determinate"
            value={percentSpent > 100 ? 100 : percentSpent}
            classes={{ root: classes.progressBar, bar: classes.progressBarFill }}
            barColor={percentSpent > 100 ? theme.palette.error.dark : theme.palette.primary.main}
          />
        </Tooltip>
        {percentSpent <= 100 &&
          <Tooltip placement="top" classes={{ tooltip: classes.tooltip }} title={`${displayPointsValue(policy.confirmedAmountSpent)} confirmed`}>
            <ProgressBar
              variant="determinate"
              value={percentConfirmed}
              classes={{
                root: classNames(classes.progressBar, classes.progressBarConfirmed),
                bar: classNames(classes.progressBarFill, classes.confirmedFill),
              }}
              barColor={theme.palette.secondary.main}
            />
          </Tooltip>
        }
      </Box>
      <Typography variant="caption" color={percentSpent > 100 ? 'error' : 'textPrimary'}>
        {progressBarText}
      </Typography>
    </Fragment>
  );
};

SelectionsProgressBar.propTypes = {
  policy: PropTypes.object.isRequired,
};

export default SelectionsProgressBar;


