const { CALL_INTAKE_V2_API } = require('middleware/intakeV2Api');
const {
  GET_AUTH_PETS_REQUEST,
  GET_AUTH_PETS_SUCCESS,
  GET_AUTH_PETS_FAILURE,
  POST_AUTH_PETS_REQUEST,
  POST_AUTH_PETS_SUCCESS,
  POST_AUTH_PETS_FAILURE,
  GET_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  GET_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  POST_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  POST_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
} = require('../types/temporaryLivingTypes');

export const getAuthPets = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_PETS_REQUEST, GET_AUTH_PETS_SUCCESS, GET_AUTH_PETS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/pets`,
      method: 'GET',
    },
  };
};

export const postAuthPets = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_PETS_REQUEST, POST_AUTH_PETS_SUCCESS, POST_AUTH_PETS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/pets/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const getTemporaryLivingAssignments = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_TEMPORARY_LIVING_ASSIGNMENT_REQUEST, GET_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS, GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/tempLiving`,
      method: 'GET',
    },
  };
};

export const postTemporaryLivingAssignments = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_TEMPORARY_LIVING_ASSIGNMENT_REQUEST, POST_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS, POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/tempLiving/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateTemporaryLivingAssignments = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        UPDATE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
        UPDATE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
        UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/tempLiving/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteTemporaryLivingAssignment = (authorizationId, temporaryLivingId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        DELETE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
        DELETE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
        DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/${authorizationId}/tempLiving/${temporaryLivingId}`,
      method: 'DELETE',
    },
  };
};
