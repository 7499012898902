import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, useTheme, withStyles  } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import HomeSaleBonus from './homeSaleBonus.component';
import HomeSaleBvoGbo from './homeSaleBvoGbo.component';
import HomeSaleDirectReimbursement from './homeSaleDirectReimbursement.component';
import HomeSaleGboAppraisalsOffer from './homeSaleGboAppraisalsOffer.component';
import HomeSaleGeneralInformation from './homeSaleGeneralInformation.component';
import HomeSaleMarketingAssistance from './homeSaleMarketingAssistance.component';
import Spinner from 'modules/common/spinner.component';

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_CLIENT_HOMESALE_INFO_FAILURE, UPDATE_CLIENT_HOMESALE_INFO_SUCCESS } from '../../types';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';
import HomeSaleClosingCosts from './homeSaleClosingCosts.component';
import HomeSaleHHGProcesses from './homeSaleHHGProcesses.component';
import HomeSaleInventoryHomes from './homeSaleInventoryHomes.component';
import HomeSaleLossOnSale from './homeSaleLossOnSale.component';
import HomeSaleMortgage from './homeSaleMortgage.component';

const styles = (theme) => ({
  accordionStyle: {
    maxWidth: 1050,
  },
  homeSaleBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  buttonContainer: {
    marginLeft: 16,
    marginTop: 16,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 16,
    marginTop: '32px',
  },
  checkbox: {
    paddingLeft: 10,
  },
});

const HomeSaleInformation = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [generalInfo, setGeneralInfo] = useState(null);
  const [marketingAssistance, setMarketingAssistance] = useState(null);
  const [directReimbursement, setDirectReimbursement] = useState(null);
  const [bvoGbo, setBvoGbo] = useState(null);
  const [gboAppraisalsOffer, setGboAppraisalsOffer] = useState(null);
  const [bonus, setBonus] = useState(null);
  const [equityAdvance, setEquityAdvance] = useState(null);
  const [lossOnSale, setLossOnSale] = useState(null);
  const [mortgage, setMortgage] = useState(null);

  const [inventoryHomes, setInventoryHomes] = useState(null);

  const theme = useTheme();

  const updateHomesaleInfo = async () => {
    const updateBody = {
      ClientHomesaleGeneral: generalInfo,
      ClientHomesaleMarketingAssistance: marketingAssistance,
      ClientHomesaleDirectReimbursement: directReimbursement,
      ClientHomesaleBvoGbo: bvoGbo,
      ClientHomesaleGboAppraisalsOffer: gboAppraisalsOffer,
      ClientHomesaleBonus: bonus,
      ClientHomesaleEquityAdvance: equityAdvance,
      ClientHomesaleLossOnSale: lossOnSale,
      ClientHomesaleMortgage: mortgage,
      ClientHomesaleInventoryHomes: inventoryHomes,
    };

    const updateResp = await props.updateClientHomesaleGeneralInfo(updateBody);
    if (updateResp.type === UPDATE_CLIENT_HOMESALE_INFO_FAILURE) {
      props.showToast('Failed to save client homesale data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } else if (updateResp.type === UPDATE_CLIENT_HOMESALE_INFO_SUCCESS) {
      await props.getClientHomesaleGeneralInfo(props.clientId);
    }
  };

  const setClosingCostsInitiated = (val) => {
    let tempGeneralInfo = {};
    if (!generalInfo) {
      tempGeneralInfo = {
        ClientId: props.clientId,
      };
    } else {
      tempGeneralInfo = { ...generalInfo };
    }
    tempGeneralInfo.ClosingCostsInitiated = val;
    setGeneralInfo(tempGeneralInfo);
  };

  const setHHGMatrixInitiated = (val) => {
    let tempGeneralInfo = {};
    if (!generalInfo) {
      tempGeneralInfo = {
        ClientId: props.clientId,
      };
    } else {
      tempGeneralInfo = { ...generalInfo };
    }
    tempGeneralInfo.HhgDetailsMatrixInitiated = val;
    setGeneralInfo(tempGeneralInfo);
  };

  return (
    <Accordion expanded={expanded} onChange={()=> {setExpanded(!expanded);}} classes={{ root: classNames(props.classes.accordionStyle) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <h2>Home Sale Information</h2>
      </AccordionSummary>
      <AccordionDetails>
        {!props.clientHomesaleGeneralInfoLoading && !props.clientPolicyNotesLoading &&
          <div className={props.classes.homeSaleBody}>
            <HomeSaleGeneralInformation clientId={props.clientId} updateInfo={(i) => setGeneralInfo(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />
            <HomeSaleMarketingAssistance clientId={props.clientId} updateInfo={(i) => setMarketingAssistance(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />
            <HomeSaleDirectReimbursement clientId={props.clientId} updateInfo={(i) => setDirectReimbursement(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />
            <HomeSaleBvoGbo clientId={props.clientId} updateInfo={(i) => setBvoGbo(i)} updateEquityAdvanceInfo={(i) => setEquityAdvance(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />
            <HomeSaleGboAppraisalsOffer clientId={props.clientId} updateInfo={(i) => setGboAppraisalsOffer(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />
            <HomeSaleBonus clientId={props.clientId} updateInfo={(i) => setBonus(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />
            
            <div className={props.classes.headerRow}>
              <h3>Closing Costs</h3>
              <FormControlLabel
                control={
                  <Checkbox
                    name="closingCosts"
                    label="Activate Service"
                    checked={generalInfo && generalInfo.ClosingCostsInitiated}
                    onChange={(e) => setClosingCostsInitiated(e.target.checked)}
                  />
                }
                label="Activate Service"
                classes={{ root: props.classes.checkbox }}
              />
            </div>
            {generalInfo && generalInfo.ClosingCostsInitiated && <HomeSaleClosingCosts clientId={props.clientId} />}

            <HomeSaleLossOnSale  clientId={props.clientId} updateInfo={(i) => setLossOnSale(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />
            <HomeSaleMortgage clientId={props.clientId} updateInfo={(i) => setMortgage(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />

            <HomeSaleInventoryHomes  clientId={props.clientId} updateInfo={(i) => setInventoryHomes(i)} clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} />
            
            <div className={props.classes.headerRow}>
              <h3>HHG Details Matrix</h3>
              <FormControlLabel
                control={
                  <Checkbox
                    name="hhgDetailsMatrixInitiated"
                    label="Activate Service"
                    checked={generalInfo && generalInfo.HhgDetailsMatrixInitiated}
                    onChange={(e) => setHHGMatrixInitiated(e.target.checked)}
                  />
                }
                label="Activate Service"
                classes={{ root: props.classes.checkbox }}
              />
            </div>
            {generalInfo && generalInfo.HhgDetailsMatrixInitiated && <HomeSaleHHGProcesses clientId={props.clientId} />}
            
            <div className={props.classes.buttonContainer}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {updateHomesaleInfo();}}
              >
                Save Info
              </Button>
            </div>
          </div>
        }
        {props.clientHomesaleGeneralInfoLoading && <Spinner />}
      </AccordionDetails>
    </Accordion>
  );  
};

HomeSaleInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  const { clientHomesaleGeneralInfo } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleGeneralInfoLoading, clientPolicyNotesLoading } = state.clients;
  return {
    clientHomesaleGeneralInfo,
    clientHomesaleGeneralInfoLoading,
    clientPolicyNotesLoading,
  };
};
  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleInformation);